<template>
  <div class="bg-white p-2">
      <div class="card-header pb-2">
          <div class="row">
              <div class="col-lg-4">
                  <h5>Dashboard</h5>
              </div>
          </div>
      </div>
  </div>
 </template>
  <script>
  export default {
    name    : 'dashboard-page',
        data (){
            return{
            }
        },
  };
  </script>
  <style lang=""></style>