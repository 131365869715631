import home from '../pages/home/home';
import listDependencias from '@/pages/catalogos/dependencias/listDependencias';
import formDependencias from '@/pages/catalogos/dependencias/formDependencias';
import permissionKeys    from '@/const/permission.const';
import Permission        from "@/class/permission.class";

function ValidatePermission(to, from, next){
    let permission = new Permission(permissionKeys.PERM_DEPENDENCIA);

    permission.existKey() ? next() : next({ name: 'no-autorizado' });
}

const routesDependencias = [{
    path        : '/catalogo',
    component   : home,
    children    :[
        { path: 'dependencias',     component: listDependencias, beforeEnter: [ValidatePermission] },
        { path: 'dependencias/add', component: formDependencias, beforeEnter: [ValidatePermission] },
        { path: 'dependencias/:id', component: formDependencias, beforeEnter: [ValidatePermission] }
    ]
}]

export default{
    getRoutes(){
        return routesDependencias;
    }
}