import home from '../pages/home/home';
import listDistritoDeRiego from '@/pages/catalogos/distritoDeRiego/listDistritoDeRiego';
import formDistritoDeRiego from '@/pages/catalogos/distritoDeRiego/formDistritoDeRiego';
import permissionKeys    from '@/const/permission.const';
import Permission        from "@/class/permission.class";

function ValidatePermission(to, from, next){
    let permission = new Permission(permissionKeys.PERM_DISTRITO_RIEGO);

    permission.existKey() ? next() : next({ name: 'no-autorizado' });
}

const routesDistritoDeRiego = [{
    path        : '/catalogo',
    component   : home,
    children    :[
        { path: 'distritoDeRiego',     component: listDistritoDeRiego, beforeEnter: [ValidatePermission] },
        { path: 'distritoDeRiego/add', component: formDistritoDeRiego, beforeEnter: [ValidatePermission] },
        { path: 'distritoDeRiego/:id', component: formDistritoDeRiego, beforeEnter: [ValidatePermission] }
    ]
}]

export default{
    getRoutes(){
        return routesDistritoDeRiego;
    }
}