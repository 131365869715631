export default{
    PERM_USUARIOS                       : 'PERM_USUARIO',
    PERM_CONFIGURACION                  : 'PERM_CONFIGURACION',
    PERM_PERMISO                        : 'PERM_PERMISOS',
    PERM_CATALOGO                       : 'PERM_CATALOGO',
    PERM_CULTIVOS                       : 'PERM_CULTIVOS',
    PERM_DISTRITO_RIEGO                 : 'PERM_DISTRITO_RIEGO',
    PERM_DISTRITO_DESARROLLO_RURAL      : 'PERM_DISTRITO_DESARROLLO_RURAL',
    PERM_MODULO_RIEGO                   : 'PERM_MODULO_RIEGO',
    PERM_CICLO_AGRICOLA                 : 'PERM_CICLO_AGRICOLA',
    PERM_CATALOGO_CONFIGURACION         : 'PERM_CATALOGO_CONFIGURACION',
    PERM_DEPENDENCIA                    : 'PERM_DEPENDENCIA',
    PERM_TIPO_DEPENDENCIA               : 'PERM_TIPO_DEPENDENCIA',
    PERM_TIPO_SIEMBRA                   : 'PERM_TIPO_SIEMBRA',
    PERM_ROLES                          : 'PERM_ROLES',
    PERM_TIPO_CICLO_AGRICOLA            : 'PERM_TIPO_CICLO_AGRICOLA',
    PERM_CLASIFICACION_CULTIVOS         : 'PERM_CLASIFICACION_CULTIVOS',
    PERM_DASHBOARD                      : 'PERM_DASHBOARD',
    PERM_REPORTES                       : 'PERM_REPORTES',
    PERM_REPORTES_AVANCE_SIEMBRA        : 'PERM_REPORTES_AVANCE_SIEMBRA',
    PERM_REPORTES_AVANCE_PERMISOS       : 'PERM_REPORTES_AVANCE_PERMISOS',
    PERM_REPORTES_PERMISO_VALIDADOS     : 'PERM_REPORTES_PERMISO_VALIDADOS',
    PERM_REPORTES_PERMISO_LOGS          : 'PERM_REPORTES_PERMISO_LOGS',
    PERM_PROGRAMA_AGRICOLA              : 'PERM_PROGRAMA_AGRICOLA',
    PERM_REPORTES_EMISION_PERMISOS      : 'PERM_REPORTES_EMISION_PERMISOS',
    PERM_REPORTES_ESTRACTO_POR_CULTIVO  : 'PERM_REPORTES_ESTRACTO_POR_CULTIVO',
}