<template>
    <div class="bg-white">
        <div class="card-header pb-2">
            <div class="row">
                <div class="col-lg-4">
                    <h5>Reporte de Estrato por Cultivo</h5>
                </div>
            </div>
        </div>
        <div class="row pb-3">
            <div class="col-sm-3">
                <label class="input-label pt-0">Cultivo</label><br>
                <select id="cultivoId" class="form-control form-control-sm" v-model="item.cultivoId" v-on:change="validItemForm('cultivoId')">
                    <option v-for="itemT in listCultivos" v-bind:value="itemT.id" >{{itemT.nombre}}</option>
                </select>
            </div>
            <div class="col-sm-3">
                <label class="input-label pt-0">Ciclo Agrícola</label><br>
                <select id="cicloAgricolaId" class="form-control form-control-sm" v-model="item.cicloAgricolaId" v-on:change="validItemForm('cicloAgricolaId')">
                    <option v-for="itemT in listCicloAgricola" v-bind:value="itemT.id" >{{itemT.nombre}}</option>
                </select>
            </div>
            <div class="col-sm-3 mt-2">
                <button class="btn btn-dark mt-4" @click="exportPDF"> Buscar y exportar</button>    
            </div>
        </div>
    </div>
</template>
<style>
</style>
<script>
    import { Icon }                 from '@iconify/vue';
    import Button                   from '@/components/plantilla/Button';
    import ReportesService          from '@/services/reportes.service';
    import CicloAgricolaService     from '@/services/cicloAgricola.service';
    import CultivoService           from '@/services/cultivo.service';
    import FormValidation           from '@/class/form-validation.class';
    import Toast                    from '@/class/toast.class';
    import GlobalMessages           from '@/const/messages.const';

     export default {
        name    : 'reporte-estrato-por-cultivo',
        data (){
            return{
                isSearch            : false,
                listCicloAgricola   : [],
                listCultivos        : [],
                item                : {},
                formValidation      : null
            }
        },
        methods:{
            getCicloAgricola(){
                CicloAgricolaService.get().then(res => {
                    this.listCicloAgricola = res;
                }).catch(err => {

                });
            },
            getCultivos(){
                CultivoService.get().then(res => {
                    this.listCultivos = [{id: -1, nombre: 'TODOS'},...res];
                }).catch(err => {

                });
            },
            exportPDF(){
                if(!this.formValidation.isValid(this.item)){
                    Toast.danger(GlobalMessages.ERROR_FORM_INVALID);
                    return;
                }

                ReportesService.exportEstractoPorCultivo(this.item).then(res => {
                    let cicloAgricolaItem = this.listCicloAgricola.find(x => x.id == this.item.cicloAgricolaId);
                    this.downloadFile(res.data, `estrato-por-cultivo-${cicloAgricolaItem.nombre}.pdf`)
                    Toast.success('Reporte descargado exitosamente');
                    this.isSearch = true;
                }).catch(err => {
                    let message = err.message && !err.message.includes('Internal') ? err.message : 'Problema para consultar el reporte';
                    Toast.danger(message);
                    this.isSearch = false;
                })
            },
            downloadFile(buffer, fileName) {
                const url   = window.URL.createObjectURL(new Blob([new Uint8Array(buffer).buffer]));
                const link  = document.createElement('a');
                link.href   = url;
                link.setAttribute('download', fileName);
                document.body.appendChild(link);
                link.click();
            },
            validItemForm(id){
                this.formValidation.validByIdForm(id, this.item[id]);
            },
        },
        mounted(){
            this.getCicloAgricola();
            this.getCultivos();

            this.formValidation = new FormValidation([
                { id: 'cultivoId',      validators:{ required: true }, default: '' },
                { id: 'cicloAgricolaId',validators:{ required: true }, default: '' },
            ]);

            this.item = this.formValidation.createStructure();
        },
        components:{
            Icon,
            Button
        }
    }
</script>