import home from '../pages/home/home';
import dashboard from '../pages/dashboard/dashboard';

const routes = [{ 
    path: '/dashboard',
    component: home,
    children    :[
        { path: '',     component: dashboard },
    ]
}]

export default{
    getRoutes(){
        return routes;
    }
}