import home from '../pages/home/home';
import listCicloAgricola from '@/pages/catalogos/cicloAgricola/listCicloAgricola';
import formCicloAgricola from '@/pages/catalogos/cicloAgricola/formCicloAgricola';
import permissionKeys    from '@/const/permission.const';
import Permission        from "@/class/permission.class";

function ValidatePermission(to, from, next){
    let permission = new Permission(permissionKeys.PERM_CICLO_AGRICOLA);

    permission.existKey() ? next() : next({ name: 'no-autorizado' });
}

const routesCicloAgricola = [{
    path        : '/catalogo',
    component   : home,
    children    :[
        { path: 'cicloAgricola',     component: listCicloAgricola, beforeEnter: [ValidatePermission] },
        { path: 'cicloAgricola/add', component: formCicloAgricola, beforeEnter: [ValidatePermission] },
        { path: 'cicloAgricola/:id', component: formCicloAgricola, beforeEnter: [ValidatePermission] }
    ]
}]

export default{
    getRoutes(){
        return routesCicloAgricola;
    }
}