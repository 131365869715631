<template>
  <ul class="pl-6">
    <li v-for="itemMenu in items" class="single-sidebar-menu">
        <div class="flex-1 flex pt-1" v-if="itemMenu.ruta != '' && validatePermission(itemMenu.permission)">
          <span class="menu-icon" v-show="itemMenu.icon">
            <Icon :icon="itemMenu.icon"
          /></span>
          <div class="text-box" >
            <router-link  v-bind:to="getPathByItem(itemMenu)" style="color: #475569; padding: 0px; text-decoration: none;" :class="`${this.$route.path === getPathByItem(itemMenu) ? 'fw-bold' : ''}`">
              {{itemMenu.nombre}}
            </router-link>  
          </div>
        </div>

        <!--primer nivel boton con childrens-->
        <div class="flex-1 flex pt-1" v-if="itemMenu.ruta == '' && validatePermission(itemMenu.permission)">
          <span class="menu-icon" v-show="itemMenu.icon">
            <Icon :icon="itemMenu.icon"
          /></span>

          <div class="text-box w-100">
            <b-button v-if="itemMenu.ruta == ''" v-b-toggle="'accordion-' + itemMenu.id" variant="link" @click="clickSelectMenu('1',itemMenu)" style="text-decoration:none; color: #475569; padding: 0px;" class="menu-link text-box flex-grow collapsed"> 
              {{itemMenu.nombre}}
            </b-button>
          </div>
          
          <span :id="'menu-icon-children-' + itemMenu.id" class="menu-icon menu-icon-children ltr:rotate-360" v-if="itemMenu.childrens.length > 0">
            <Icon icon="heroicons-outline:chevron-down"
          /></span>
        </div>
       
        <b-collapse class="menuPrincipal"  v-bind:id="'accordion-' + itemMenu.id" accordion="my-accordion" role="tabpanel" v-if="itemMenu.childrens.length > 0">
          <ul class="menu-ul">
              <li class="single-sidebar-menu" v-for="itemSub in itemMenu.childrens" style="border: 0px; text-align-last: left; border: none;">
                  
                <div class="flex-1 flex pt-1" v-if="itemSub.ruta != '' && validatePermission(itemSub.permission)">
                  <div class="text-box" >
                    <router-link  v-bind:to="getPathByItem(itemSub)" style="color: #475569; padding: 0px; text-decoration: none;" :class="`${this.$route.path === getPathByItem(itemSub) ? 'fw-bold' : ''}`">
                      {{itemSub.nombre}}
                    </router-link>  
                  </div>
                </div>   

                <!--segundo nivel boton con childrens-->
                <div class="flex-1 flex pt-1" v-if="itemSub.ruta == ''">
                  <span class="menu-icon" v-show="itemSub.icon">
                    <Icon :icon="itemSub.icon"
                  /></span>
                  
                  <div class="text-box w-100">
                    <b-button v-if="itemSub.ruta == '' && validatePermission(itemSub.permission)" v-b-toggle="'accordion-' + itemSub.id" variant="link" @click="clickSelectMenu('2',itemSub)" style="text-decoration:none; color: #475569; padding: 0px;" class="menu-link text-box flex-grow collapsed"> 
                      {{itemSub.nombre}}
                    </b-button>
                  </div>

                  <span :id="'menu-icon-children-' + itemSub.id" class="menu-icon menu-icon-children ltr:rotate-360" v-if="itemSub.childrens.length > 0">
                    <Icon icon="heroicons-outline:chevron-down"
                  /></span>
                </div>

                <b-collapse  v-bind:id="'accordion-' + itemSub.id" accordion="my-accordion" role="tabpanel" v-if="itemSub.childrens.length > 0">
                        <ul class="menu-ul">
                            <li class="single-sidebar-menu" v-for="itemSubChil in itemSub.childrens" style="text-align: end; border: none;">
                                                                  
                              <div class="flex-1 flex pt-1" v-if="itemSubChil.ruta != '' && validatePermission(itemSubChil.permission)">
                                <!--<span class="menu-icon" v-show="itemSubChil.icon">
                                  <Icon :icon="itemSubChil.icon"
                                /></span>-->
                                <div class="text-box text-start" >
                                  <router-link  v-bind:to="getPathByItem(itemSubChil)" style="color: #475569; padding: 0px; text-decoration: none;" :class="`${this.$route.path === getPathByItem(itemSubChil) ? 'fw-bold' : ''}`">
                                    {{itemSubChil.nombre}}
                                  </router-link>  
                                </div>
                              </div>
                              
                            </li>
                        </ul>
                </b-collapse>
              </li>
          </ul>
        </b-collapse>
    </li>
</ul>
</template>
<script>
import { useRouter } from "vue-router";
import Icon from "../Icon";
import Permission        from "@/class/permission.class";
import permissionConst from "@/const/permission.const";
export default {
  components: {
    Icon,
  },

  data() {
    return {
      activeSubmenu: null,
      items: [
        {
            id: 8,
            nombre: "Dashboard",
            ruta: "dashboard",
            icon: "heroicons-outline:home",
            menuOpcionId: null,
            orden: 1,
            hasIcon:0,
            permission : permissionConst.PERM_DASHBOARD,
            childrens:[]
        },
        {
            id: 2,
            nombre: "Permisos",
            ruta: "permisos",
            icon: "heroicons:newspaper",
            menuOpcionId: null,
            orden: 1,
            hasIcon:0,
            permission : permissionConst.PERM_PERMISO,
            childrens:[]
        },
        {
            id: 4,
            nombre: "Reportes",
            ruta: "",
            icon: "heroicons:chart-pie",
            menuOpcionId: null,
            orden: 1,
            hasIcon:0,
            permission : permissionConst.PERM_REPORTES,
            childrens:[
              {
                  id: 50,
                  nombre: "Informe de Avance de Siembra",
                  ruta: "reportes",
                  menuOpcionId: 1,
                  orden: null,
                  permission : permissionConst.PERM_REPORTES_AVANCE_SIEMBRA,
                  childrens: []
              },
              {
                  id: 51,
                  nombre: "Informe de Avance de Permisos",
                  ruta: "reportes/avance-siembra-por-permisos",
                  menuOpcionId: 1,
                  orden: null,
                  permission : permissionConst.PERM_REPORTES_AVANCE_PERMISOS,
                  childrens: []
              },
              {
                  id: 52,
                  nombre: "Informe de Permiso Válidado",
                  ruta: "reportes/permiso-validado",
                  menuOpcionId: 1,
                  orden: null,
                  permission : permissionConst.PERM_REPORTES_PERMISO_VALIDADOS,
                  childrens: []
              },
              {
                  id: 59,
                  nombre: "Logs de Permisos",
                  ruta: "reportes/permiso-logs",
                  menuOpcionId: 1,
                  orden: null,
                  permission : permissionConst.PERM_REPORTES_PERMISO_VALIDADOS,
                  childrens: []
              },
              {
                  id: 60,
                  nombre: "Emisión de Permisos",
                  ruta: "reportes/emision-permiso-siembra",
                  menuOpcionId: 1,
                  orden: null,
                  permission : permissionConst.PERM_REPORTES_EMISION_PERMISOS,
                  childrens: []
              },
              {
                  id: 61,
                  nombre: "Estrato por Cultivo",
                  ruta: "reportes/estracto-por-cultivo",
                  menuOpcionId: 1,
                  orden: null,
                  permission : permissionConst.PERM_REPORTES_ESTRACTO_POR_CULTIVO,
                  childrens: []
              },
            ]
        },
        {
            id: 1,
            nombre: "Catálogos",
            ruta: "",
            icon: "heroicons-outline:bars-4",
            menuOpcionId: null,
            orden: 1,
            hasIcon:0,
            permission : permissionConst.PERM_CATALOGO,
            childrens: [  
                {
                    id: 4,
                    nombre: "Distrito de Desarrollo Rural",
                    ruta: "catalogo/distritoDeDesarrolloRural",
                    menuOpcionId: 1,
                    orden: null,
                    permission : permissionConst.PERM_DISTRITO_DESARROLLO_RURAL,
                    childrens: []
                },
                {
                    id: 5,
                    nombre: "Distrito de Riego",
                    ruta: "catalogo/distritoDeRiego",
                    menuOpcionId: 1,
                    orden: null,
                    permission : permissionConst.PERM_DISTRITO_RIEGO,
                    childrens: []
                },
                {
                    id: 6,
                    nombre: "Módulo de Riego",
                    ruta: "catalogo/moduloDeRiego",
                    menuOpcionId: 1,
                    orden: null,
                    permission : permissionConst.PERM_MODULO_RIEGO,
                    childrens: []
                },               
                {
                    id: 9,
                    nombre: "Cultivos",
                    ruta: "catalogo/cultivos",
                    menuOpcionId: 1,
                    orden: null,
                    permission : permissionConst.PERM_CULTIVOS,
                    childrens: []
                },
                {
                    id: 10,
                    nombre: "Ciclos Agricola",
                    ruta: "catalogo/cicloAgricola",
                    menuOpcionId: 1,
                    orden: null,
                    permission : permissionConst.PERM_CICLO_AGRICOLA,
                    childrens: []
                }                
            ]
        },
        {
            id: 3,
            nombre: "Configuración",
            ruta: "",
            icon: "heroicons-outline:cog-8-tooth",
            menuOpcionId: null,
            orden: 1,
            hasIcon:0,
            permission : permissionConst.PERM_CONFIGURACION,
            childrens:[
                {
                    id: 13,
                    nombre: "Usuarios",
                    ruta: "configuracion/usuarios",
                    menuOpcionId: 1,
                    orden: null,
                    permission : permissionConst.PERM_USUARIOS,
                    childrens: []
                },
                {
                    id: 14,
                    nombre: "Catálogos",
                    icon: "",
                    ruta: "",
                    menuOpcionId: 1,
                    orden: null,
                    permission : permissionConst.PERM_CATALOGO_CONFIGURACION,
                    childrens: [
                      {
                        id: 2,
                        nombre: "Dependencias",
                        ruta: "catalogo/dependencias",
                        menuOpcionId: 1,
                        orden: null,
                        permission : permissionConst.PERM_DEPENDENCIA,
                        childrens: []
                      },
                      {
                        id: 3,
                        nombre: "Tipo Dependencia",
                        ruta: "catalogo/tipoDependencia",
                        menuOpcionId: 1,
                        orden: null,
                        permission : permissionConst.PERM_TIPO_DEPENDENCIA,
                        childrens: []
                      },
                      {
                        id: 7,
                        nombre: "Tipo de Siembra",
                        ruta: "catalogo/tipoSiembra",
                        menuOpcionId: 1,
                        orden: null,
                        permission : permissionConst.PERM_TIPO_SIEMBRA,
                        childrens: []
                      },
                      {
                        id: 8,
                        nombre: "Roles",
                        ruta: "catalogo/roles",
                        menuOpcionId: 1,
                        orden: null,
                        permission : permissionConst.PERM_ROLES,
                        childrens: []
                      },
                      {
                        id: 11,
                        nombre: "Tipos de Ciclos Agricolas",
                        ruta: "catalogo/tipoCicloAgricola",
                        menuOpcionId: 1,
                        orden: null,
                        permission : permissionConst.PERM_TIPO_CICLO_AGRICOLA,
                        childrens: []
                      },
                      {
                        id: 12,
                        nombre: "Clasificación de Cultivos",
                        ruta: "catalogo/cultivoClasificacion",
                        menuOpcionId: 1,
                        orden: null,
                        permission : permissionConst.PERM_CLASIFICACION_CULTIVOS,
                        childrens: []
                      },
                      {
                        id: 13,
                        nombre: "Programa Agrícola",
                        ruta: "catalogo/programa-agricola",
                        menuOpcionId: 1,
                        orden: null,
                        permission : permissionConst.PERM_PROGRAMA_AGRICOLA,
                        childrens: []
                      }
                    ]
                }
            ]
        }
      ]
    };
  },
  methods: {
    beforeEnter(element) {
      requestAnimationFrame(() => {
        if (!element.style.height) {
          element.style.height = "0px";
        }

        element.style.display = null;
      });
    },
    enter(element) {
      requestAnimationFrame(() => {
        requestAnimationFrame(() => {
          element.style.height = `${element.scrollHeight}px`;
        });
      });
    },
    afterEnter(element) {
      element.style.height = null;
    },
    beforeLeave(element) {
      requestAnimationFrame(() => {
        if (!element.style.height) {
          element.style.height = `${element.offsetHeight}px`;
        }
      });
    },
    leave(element) {
      requestAnimationFrame(() => {
        requestAnimationFrame(() => {
          element.style.height = "0px";
        });
      });
    },
    afterLeave(element) {
      element.style.height = null;
    },
    toggleSubmenu(index) {
      if (this.activeSubmenu === index) {
        this.activeSubmenu = null;
      } else {
        this.activeSubmenu = index;
      }
    },
    getIdDinamic: function(id){
        return `dropdown-${id}`;
    },
    getPathByItem: function(item){
        return `/${item.ruta}`;
    },
    validatePermission(keyPermission){
      let permission = new Permission(keyPermission);
      return permission.existKey();
    },
    clickSelectMenu(nivel, item){  
      if(nivel == 1)
        $(".menuPrincipal").removeClass("show");
      
      if($("#menu-icon-children-"+item.id).hasClass("ltr:rotate-360")){        
        $("#menu-icon-children-"+item.id).removeClass("ltr:rotate-360");     
        $("#menu-icon-children-"+item.id).addClass("ltr:rotate-180");
      }else{      
        $("#menu-icon-children-"+item.id).removeClass("ltr:rotate-180");     
        $("#menu-icon-children-"+item.id).addClass("ltr:rotate-360");
      }

    }
  },
  watch: {
    $route() {
      if (this.$store.themeSettingsStore.mobielSidebar) {
        this.$store.themeSettingsStore.mobielSidebar = false;
      }

      this.items.map((item) => {
        if (item.link === this.$route.name) {
          this.activeSubmenu = null;
        }
      });
    },
  },

  created() {
    const router = useRouter();
    this.items.map((item, i) => {
      item.child?.map((ci) => {
        if (ci.childlink === router.currentRoute.value.name) {
          this.activeSubmenu = i;
        }
      });
    });
  },
  updated() {},
};
</script>
<style lang="scss">
.submenu_enter-active,
.submenu_leave-active {
  overflow: hidden;
  transition: all 0.34s linear;
}

.not-collapsed .has-icon {
  transition: all 0.34s linear;
}
.not-collapsed .has-icon {
  @apply transform rotate-180;
}

// single sidebar menu css
.single-sidebar-menu {
  @apply relative;
  .menulabel {
    @apply text-slate-800 dark:text-slate-300 text-xs font-semibold uppercase mb-4 mt-4;
  }
  > .menu-link {
    @apply flex text-slate-600 font-medium dark:text-slate-300 text-sm capitalize px-[10px] py-3 rounded-[4px] cursor-pointer;
  }
  .menu-icon {
    @apply icon-box inline-flex items-center text-slate-600 dark:text-slate-300 text-lg ltr:mr-3 rtl:ml-3;
  }
}
// menu item has chilren
.item-has-children {
  .menu-arrow {
    @apply h-5 w-5 text-base text-slate-300 bg-slate-100 dark:bg-[#334155] dark:text-slate-300 rounded-full flex justify-center items-center;
  }
}

// close sidebar css
.close_sidebar .menulabel {
  @apply hidden;
}

.close_sidebar:not(.sidebar-hovered) {
  .menu-arrow {
    @apply hidden;
  }
  .single-sidebar-menu {
    .text-box {
      @apply absolute  left-full ml-5 w-[180px] top-0 px-4 py-3 bg-white shadow-dropdown rounded-[4px] dark:bg-slate-800 z-[999] invisible opacity-0 transition-all duration-150;
    }
    &:hover {
      .text-box {
        @apply visible opacity-100;
      }
    }
  }
  .item-has-children {
    .text-box {
      @apply hidden;
    }

    > ul {
      @apply ml-4 absolute left-full top-0 w-[230px] bg-white shadow-dropdown rounded-[4px] dark:bg-slate-800 z-[999] px-4 pt-3 transition-all duration-150 invisible opacity-0;
      display: block !important;
    }
    &:hover {
      > ul {
        @apply visible opacity-100;
      }
    }
  }
}
.menu-badge {
  @apply py-1 px-2 text-xs capitalize font-semibold rounded-[.358rem] whitespace-nowrap align-baseline inline-flex bg-slate-900 text-slate-50 dark:bg-slate-700 dark:text-slate-300;
}
// active menu
.item-has-children {
  .parent_active {
    @apply bg-secondary-500 bg-opacity-20;
    .icon-box,
    .menu-icon,
    .text-box {
      @apply text-slate-700 dark:text-slate-200;
    }
    .menu-arrow {
      @apply bg-secondary-500 text-slate-600 text-opacity-70 bg-opacity-30 dark:text-white;
    }
  }
}
.menu-item-active {
  .menu-link {
    @apply bg-slate-800 dark:bg-slate-700;
    .icon-box,
    .menu-icon,
    .text-box {
      @apply text-white dark:text-slate-300;
    }
  }
  .menu-badge {
    @apply bg-slate-100  text-slate-900;
  }
}

.menu-ul {
  padding-left: 2rem;
}
</style>
