<template>
    <div class="bg-white">        
        <div class="card-header pb-0">
            <div class="row">
                <div class="col-lg-4">
                    <h5>{{title}}</h5>
                </div>
            </div>
        </div>
        <div class="card-body">
            <form autocomplete="off" class="pace-y-4">
                <div class="row">
                   <div class="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
                        <div class="form-group row pb-3">
                            <label class="input-label pt-0 col-sm-3">Nombre</label>
                             <div class="col-sm-9">
                                <Textinput
                                    name="nombre"
                                    classInput="form-control form-control-sm"
                                    v-model="item.nombre"
                                    v-on:keydown="validItemForm('nombre')"
                                    v-on:keyup="validItemForm('nombre')"
                                    placeholder=""
                                    />
                            </div>
                        </div>
                    </div>
                    <div class="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
                        <div class="form-group row pb-3">
                            <label class="input-label pt-0 col-sm-4">Ciclo Agrícola</label>
                             <div class="col-sm-8">
                                <select id="cicloAgricolaId" class="form-control form-control-sm" v-model="item.cicloAgricolaId" v-on:change="validItemForm('cicloAgricolaId')">
                                    <option v-for="itemT in listCicloAgricola" v-bind:value="itemT.id" >{{itemT.nombre}}</option>
                                </select>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
                        <div class="form-group row pb-3">
                            <label class="input-label pt-0 col-sm-3">Cultivo</label>
                             <div class="col-sm-9">
                                <v-select
                                    multiple
                                    label="nombre"
                                    id="cultivoId"
                                    :options="results"
                                    @search="search"
                                    v-model="item.cultivoId"
                                    :reduce="cultivo => cultivo.id">
                                    <template #no-options="{ search, searching, loading }">
                                        Sin resultados
                                    </template>
                                    <input class="autocomplete">
                                </v-select>
                            </div>
                        </div>
                    </div>
                    <div class="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
                        <div class="form-group row pb-3">
                            <label class="input-label pt-0 col-sm-4">Superfie Programado</label>
                             <div class="col-sm-8">
                                <Textinput
                                    name="programado"
                                    classInput="form-control"
                                    v-model="item.programado"
                                    v-on:keydown="validItemForm('programado')"
                                    v-on:keyup="validItemForm('programado')"
                                    :options="formatoNumber"
                                    placeholder=""
                                    isMask/>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
                        <div class="form-group row pb-3">
                            <label class="input-label pt-0 col-sm-3">Ciclo Anterior</label>
                             <div class="col-sm-9">
                                <select id="cicloAgricolaAnteriorId" class="form-control form-control-sm" v-model="item.cicloAgricolaAnteriorId" v-on:change="validItemForm('cicloAgricolaAnteriorId')">
                                    <option v-for="itemT in listCicloAgricola" v-bind:value="itemT.id" >{{itemT.nombre}}</option>
                                </select>
                            </div>
                        </div>
                    </div>
                    <div class="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
                        <div class="form-group row pb-3">
                            <label class="input-label pt-0 col-sm-4">Superficie Anterior</label>
                             <div class="col-sm-8">
                                <Textinput
                                    name="programadoAnterior"
                                    classInput="form-control"
                                    v-model="item.programadoAnterior"
                                    v-on:keydown="validItemForm('programadoAnterior')"
                                    v-on:keyup="validItemForm('programadoAnterior')"
                                    :options="formatoNumber"
                                    placeholder=""
                                    isMask/>
                            </div>
                        </div>  
                    </div>
                </div>
                <div class="row">
                    <div class="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
                        <div class="form-group row pb-3">
                            <label class="input-label pt-0 col-sm-3">Ciclo Antes Anterior</label>
                             <div class="col-sm-9">
                                <select id="cicloAgricolaAntesAnteriorId" class="form-control form-control-sm" v-model="item.cicloAgricolaAntesAnteriorId" v-on:change="validItemForm('cicloAgricolaAntesAnteriorId')">
                                    <option v-for="itemT in listCicloAgricola" v-bind:value="itemT.id" >{{itemT.nombre}}</option>
                                </select>
                            </div>
                        </div> 
                    </div>
                    <div class="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
                        <div class="form-group row pb-3">
                            <label class="input-label pt-0 col-sm-4">Superficie Antes Anterior</label>
                             <div class="col-sm-8">
                                <Textinput
                                    name="ProgramadoAntesAnterior"
                                    classInput="form-control"
                                    v-model="item.ProgramadoAntesAnterior"
                                    v-on:keydown="validItemForm('ProgramadoAntesAnterior')"
                                    v-on:keyup="validItemForm('ProgramadoAntesAnterior')"
                                    :options="formatoNumber"
                                    placeholder=""
                                    isMask/>
                            </div>
                        </div> 
                    </div>
                </div>
                <div class="row">
                    <div class="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
                        <div class="form-group row pb-3">
                            <label class="input-label pt-0 col-sm-3">¿Es Perenes?</label>
                             <div class="col-lg-2 col-sm-6">
                                <Switch id="esPerenes" v-model="item.esPerenes" name="esPerenes" class="mb-5"/>
                            </div>
                        </div> 
                    </div>
                </div>
                <div class="row">
                    <div class="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6 offset-md-6 offset-lg-6 offset-xl-6">
                        <div class="form-group row pb-3 text-right"> 
                            <div class="col-12">
                                <router-link to="/catalogo/programa-agricola" class="text-white">
                                    <button class="btn btn-danger">
                                        Regresar
                                    </button>
                                </router-link>
                                <button type="button" @click="save" class="ml-1 btn btn-dark">Guardar</button>
                            </div>
                        </div>
                    </div>
                </div>              
            </form>
        </div>
    </div>
</template>
<style>
.v-select {
    &.is-invalid {
        div{
            @apply border-danger-500;
        }
    }
    &.is-valid {
        div{
            @apply border-success-500;
        }
    }
}
</style>
<script>
    import cultivoService           from "@/services/cultivo.service";
    import _                        from 'underscore';
    import Toast                    from '@/class/toast.class';
    import FormValidation           from '@/class/form-validation.class';
    import GlobalMessages           from '@/const/messages.const';
    import CicloAgricolaService     from '@/services/cicloAgricola.service';
    import programaAgricolaService  from '@/services/programaAgricola.service';
    import Button                   from '@/components/plantilla/Button';
    import Textinput                from "@/components/plantilla/Textinput";
    import Switch                   from '@/components/plantilla/Switch';

    export default{
        name: 'form-programa-agricola',
        data(){
            return {
                title               : 'Nuevo Programa Agrícola',
                id                  : 0,
                item                : {},
                formValidation      : null,
                results             : [],
                listCicloAgricola   : [],
                timeOutSearch       : null,
                isSwitchActived     : false,
                formatoNumber: {
                    numeral                     : true,
                    numeralThousandsGroupStyle  : "thousand",
                }
            }
        },
        methods:{           
            getById(){
                programaAgricolaService.getId(this.id).then(async res => {
                    let cultivos    = res.cultivos;
                    delete res.cultivos;
                    this.item       = {...res, cultivoId: cultivos.map(x => x.id)};
                    this.results    = cultivos;

                    if(res.esPerenes == '1'){
                        $('#esPerenes').click();
                    }
                }).catch(error => {
                    console.log(error)
                });
            },            
            getDataSelecteds(){
                this.getCicloAgricolas();
            },
            getCicloAgricolas(){
                CicloAgricolaService.get().then(res => {
                    this.listCicloAgricola = res;
                }).catch(err => {

                });
            },
            validItemForm(id){
                this.formValidation.validByIdForm(id, this.item[id]);
            },
            save(){
                if(!this.formValidation.isValid(this.item)){
                    Toast.danger(GlobalMessages.ERROR_FORM_INVALID);
                    return;
                }

                programaAgricolaService[this.id != 0 ? 'update' : 'create'](this.toJSONSave()).then(res => {
                    Toast.success(`Programa Agrícola ${this.id != '' ? 'actualizado' : 'creado'} exitosamente`);
                    this.$router.push('/catalogo/programa-agricola');
                }).catch(err => {
                    let message = err.message && !err.message.includes('Internal') ? err.message : this.id != 0 ? GlobalMessages.ERROR_UPDATED_DATA : GlobalMessages.ERROR_CREATE_DATA;
                    Toast.danger(message);
                });
            },
            search(search, loading) {
                if (search.length < 3) { return; }

                loading(true);

                if(this.timeOutSearch != null){
                    clearTimeout(this.timeOutSearch);
                }

                let arrayCultivosSeleccionados = this.results.filter(cultivo => this.item.cultivoId.indexOf(cultivo.id) != -1);

                this.timeOutSearch = setTimeout(() => {
                    cultivoService.filterByName(search).then(res => {
                        this.results= res;
                        let ids     = this.results.map(x => x.id);

                        arrayCultivosSeleccionados.forEach(cultivo => {
                            if(!ids.includes(cultivo.id))
                                this.results.push(cultivo);
                        });

                        this.results.sort((a, b) => {
                            const nameA = a.nombre.toUpperCase();
                            const nameB = b.nombre.toUpperCase();
                            if (nameA < nameB) {
                                return -1;
                            }
                            if (nameA > nameB) {
                                return 1;
                            }

                            return 0;
                        });
                        loading(false);
                    }).catch(err => {
                        loading(false);
                    });
                },600);
            },
            toJSONSave(){
                let param = {
                    ...this.item,
                    programado              : this.toParseInt(this.item, 'programado'),
                    programadoAnterior      : this.toParseInt(this.item, 'programadoAnterior'),
                    ProgramadoAntesAnterior : this.toParseInt(this.item, 'ProgramadoAntesAnterior')
                };

                if(this.id != 0){
                    param.id = this.id;
                }

                return param;
            },
            toParseInt(param, field){
                return param[field] ? parseFloat(param[field].toString().replace(',', '')) : '';
            }
        },
        mounted(){
            let arrayControls = [
                { id: 'cicloAgricolaId',                validators:{ required: true }, default: '' },
                { id: 'nombre',                         validators:{ required: false, maxLength: 100 }, default: '' },
                { id: 'cultivoId',                      validators:{ required: true }, default: '' },
                { id: 'cicloAgricolaAnteriorId',        validators:{ required: false }, default: '' },
                { id: 'cicloAgricolaAntesAnteriorId',   validators:{ required: false }, default: '' },
                { id: 'programadoAnterior',             validators:{ required: false, maxLength: 18 }, default: '' },
                { id: 'programado',                     validators:{ required: true,  maxLength: 18 }, default: '' },
                { id: 'ProgramadoAntesAnterior',        validators:{ required: false, maxLength: 18 }, default: '' },
                { id: 'esPerenes',                      validators:{ required: false }, default: false },
            ];

            if(this.$route.params && this.$route.params.id){
                this.title  = 'Editar Programa Agrícola';
                this.id     = this.$route.params.id;
                this.getById();
            }

            this.formValidation = new FormValidation(arrayControls);
            this.item           = this.formValidation.createStructure();
            this.getDataSelecteds();
        },
        components:{
            Button,
            Textinput,
            Switch
        }
    }
</script>