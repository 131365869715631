import axios from "axios";

const API =  'permission';

export default{
    paginated(filters){
        return axios.post(`${API}/paginated`, filters);
    },
    paginatedLogs(filters){
        return axios.post(`${API}/paginated/logs`, filters);
    },
    getPermissionLogById(id){
        return axios.get(`${API}/log/${id}`);
    },
    PDF(id){
        return axios.get(`${API}/${id}/pdf`);
    },
}