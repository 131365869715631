import home             from '../pages/home/home';
import listPermisos     from '@/pages/permisos/listPermisos';
import permissionKeys   from '@/const/permission.const';
import Permission       from "@/class/permission.class";

function ValidatePermission(to, from, next){
    let permission = new Permission(permissionKeys.PERM_PERMISO);

    permission.existKey() ? next() : next({ name: 'no-autorizado' });
}

const routes = [{
    path        : '/permisos',
    component   : home,
    children    :[
        { path: '',     component: listPermisos,    beforeEnter: [ValidatePermission] }
    ]
}]

export default{
    getRoutes(){
        return routes;
    }
}