<template>
    <div class="bg-white">
        <div class="card-header pb-2">
            <div class="row">
                <div class="col-lg-4">
                    <h5>Avance de Permiso válidado</h5>
                </div>
            </div>
        </div>
        <div class="row pb-3">
            <div class="col-sm-3">
                <label class="input-label pt-0">Ciclo Agrícola</label><br>
                <select id="cicloAgricolaId" class="form-control form-control-sm" v-model="item.cicloAgricolaId" v-on:change="validItemForm('cicloAgricolaId')">
                    <option v-for="itemT in listCicloAgricola" v-bind:value="itemT.id" >{{itemT.nombre}}</option>
                </select>
            </div>
            <div class="col-sm-3 mt-2">
                <button class="btn btn-dark mt-4" @click="exportExcel"> Buscar y exportar</button>    
            </div>
        </div>
    </div>
</template>
<style>
</style>
<script>
    import { Icon }                 from '@iconify/vue';
    import Button                   from '@/components/plantilla/Button';
    import ReportesService          from '@/services/reportes.service';
    import CicloAgricolaService     from '@/services/cicloAgricola.service';
    import FormValidation           from '@/class/form-validation.class';
    import env                      from '@/config/env';
    import Toast                    from '@/class/toast.class';
    import GlobalMessages           from '@/const/messages.const';

     export default {
        name    : 'avances-emision-permisos',
        data (){
            return{
                isSearch            : false,
                listCicloAgricola   : [],
                item                : {},
                formValidation      : null
            }
        },
        methods:{
            getCicloAgricola(){
                CicloAgricolaService.get().then(res => {
                    this.listCicloAgricola = res;
                }).catch(err => {

                });
            },
            exportExcel(){
                if(!this.formValidation.isValid(this.item)){
                    Toast.danger(GlobalMessages.ERROR_FORM_INVALID);
                    return;
                }

                ReportesService.exportPermisoValidados(this.item).then(res => {
                    this.downloadFile(res.fileName);
                    Toast.success('Reporte descargado exitosamente');
                    this.isSearch = true;
                }).catch(err => {
                    Toast.danger('Problema para consultar el reporte');
                    this.isSearch = false;
                })
            },
            downloadFile(fileName) {
                const a     = document.createElement('a')
                a.href      = `${env.API_REST.replace('/api','')}/${fileName}`
                a.download  = fileName;
                a.click()
            },
            validItemForm(id){
                this.formValidation.validByIdForm(id, this.item[id]);
            },
        },
        mounted(){
            this.getCicloAgricola();

            this.formValidation = new FormValidation([
                { id: 'cicloAgricolaId', validators:{ required: true }, default: '' },
            ]);

            this.item = this.formValidation.createStructure();
        },
        components:{
            Icon,
            Button
        }
    }
</script>