import home from '../pages/home/home';
import listTipoCicloAgricola from '@/pages/catalogos/tipoCicloAgricola/listTipoCicloAgricola';
import formTipoCicloAgricola from '@/pages/catalogos/tipoCicloAgricola/formTipoCicloAgricola';
import permissionKeys    from '@/const/permission.const';
import Permission        from "@/class/permission.class";

function ValidatePermission(to, from, next){
    let permission = new Permission(permissionKeys.PERM_TIPO_CICLO_AGRICOLA);

    permission.existKey() ? next() : next({ name: 'no-autorizado' });
}

const routesTipoCicloAgricola = [{
    path        : '/catalogo',
    component   : home,
    children    :[
        { path: 'tipoCicloAgricola',     component: listTipoCicloAgricola, beforeEnter: [ValidatePermission] },
        { path: 'tipoCicloAgricola/add', component: formTipoCicloAgricola, beforeEnter: [ValidatePermission] },
        { path: 'tipoCicloAgricola/:id', component: formTipoCicloAgricola, beforeEnter: [ValidatePermission] }
    ]
}]

export default{
    getRoutes(){
        return routesTipoCicloAgricola;
    }
}