<template>
    <div class="bg-white">        
        <div class="card-header pb-0">
            <div class="row">
                <div class="col-lg-4">
                    <h5>{{title}}</h5>
                </div>
            </div>
        </div>
        <div class="card-body">
            <form autocomplete="off" class="pace-y-4">
                <div class="row">
                   <div class="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">

                        <div class="form-group row pb-3">
                            <label class="input-label pt-0 col-sm-3">Nombre</label>
                             <div class="col-sm-9">
                                <b-form-input id="nombre" class="form-control form-control-sm" v-model="item.nombre" v-on:keydown="validItemForm('nombre')" v-on:keyup="validItemForm('nombre')"></b-form-input>
                            </div>
                        </div> 
                        
                        <div class="form-group row pb-3">
                            <label class="input-label pt-0 col-sm-3">Calle</label>
                             <div class="col-sm-9">
                                <b-form-input id="calle" class="form-control form-control-sm" v-model="item.calle" v-on:keydown="validItemForm('calle')" v-on:keyup="validItemForm('calle')"></b-form-input>
                            </div>
                        </div>


                        <div class="form-group row pb-3">
                            <label class="input-label pt-0 col-sm-3">Numero Exterior</label>
                             <div class="col-sm-9">
                                <b-form-input id="numeroExterior" class="form-control form-control-sm" v-model="item.numeroExterior" v-on:keydown="validItemForm('numeroExterior')" v-on:keyup="validItemForm('numeroExterior')"></b-form-input>
                            </div>
                        </div>

                        <div class="form-group row pb-3">
                            <label class="input-label pt-0 col-sm-3">Localidad</label>
                             <div class="col-sm-9">
                                <b-form-input id="localidad" class="form-control form-control-sm" v-model="item.localidad" v-on:keydown="validItemForm('localidad')" v-on:keyup="validItemForm('localidad')"></b-form-input>
                            </div>
                        </div>

                        <div class="form-group row pb-3">
                            <label class="input-label pt-0 col-sm-3">Estado</label>
                             <div class="col-sm-9">
                                <select id="estadoId" class="form-control form-control-sm" v-model="item.estadoId" v-on:change="validItemForm('estadoId')">
                                    <option v-for="itemT in estados" v-bind:value="itemT.id" >{{itemT.nombre}}</option>
                                </select>
                            </div>
                        </div>                                  

                        <div class="form-group row pb-3">
                            <label class="input-label pt-0 col-sm-3">Mostrar en Reportes</label>
                             <div class="col-sm-9">
                                <b-form-checkbox id="mostrarEnReportex" v-model="item.mostrarEnReportex" v-on:keydown="validItemForm('mostrarEnReportex')" v-on:keyup="validItemForm('mostrarEnReportex')"></b-form-checkbox>
                            </div>
                        </div>

                        <div class="form-group row pb-3">
                            <label class="input-label pt-0 col-sm-3">Teléfono Contacto</label>
                             <div class="col-sm-9">
                                <b-form-input id="telefonoDeContacto" class="form-control form-control-sm" v-model="item.telefonoDeContacto" v-on:keydown="validItemForm('telefonoDeContacto')" v-on:keyup="validItemForm('telefonoDeContacto')"></b-form-input>
                            </div>
                        </div>

                        <div class="form-group row pb-3">
                            <label class="input-label pt-0 col-sm-3">Sitio Web</label>
                             <div class="col-sm-9">
                                <b-form-input id="sitioWeb" class="form-control form-control-sm" v-model="item.sitioWeb" v-on:keydown="validItemForm('sitioWeb')" v-on:keyup="validItemForm('sitioWeb')"></b-form-input>
                            </div>
                        </div>   

                        <div class="form-group row pb-3">
                            <label class="input-label pt-0 col-sm-3">Permite RFC Generico</label>
                             <div class="col-sm-9">
                                <b-form-checkbox id="permiteRfcGenerico" v-model="item.permiteRfcGenerico" v-on:keydown="validItemForm('permiteRfcGenerico')" v-on:keyup="validItemForm('permiteRfcGenerico')"></b-form-checkbox>
                            </div>
                        </div>
                    </div>
                    <div class="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
                        <div class="form-group row pb-3">
                            <label class="input-label pt-0 col-sm-3">Nombre Corto</label>
                             <div class="col-sm-9">
                                <b-form-input id="nombreCorto" class="form-control form-control-sm" v-model="item.nombreCorto" v-on:keydown="validItemForm('nombreCorto')" v-on:keyup="validItemForm('nombreCorto')"></b-form-input>
                            </div>
                        </div>  

                        <div class="form-group row pb-3">
                            <label class="input-label pt-0 col-sm-3">Numero Interior</label>
                             <div class="col-sm-9">
                                <b-form-input id="numeroInterior" class="form-control form-control-sm" v-model="item.numeroInterior" v-on:keydown="validItemForm('numeroInterior')" v-on:keyup="validItemForm('numeroInterior')"></b-form-input>
                            </div>
                        </div>  
                        
                        <div class="form-group row pb-3">
                            <label class="input-label pt-0 col-sm-3">Colonia</label>
                             <div class="col-sm-9">
                                <b-form-input id="colonia" class="form-control form-control-sm" v-model="item.colonia" v-on:keydown="validItemForm('colonia')" v-on:keyup="validItemForm('colonia')"></b-form-input>
                            </div>
                        </div>

                        <div class="form-group row pb-3">
                            <label class="input-label pt-0 col-sm-3">Municipio</label>
                             <div class="col-sm-9">
                                <select id="municipioId" class="form-control form-control-sm" v-model="item.municipioId" v-on:change="validItemForm('municipioId')" v-on:keyup="validItemForm('municipioId')">
                                    <option v-for="itemT in municipios" v-bind:value="itemT.id" >{{itemT.nombre}}</option>
                                </select>
                            </div>
                        </div>                           
                        
                        <div class="form-group row pb-3">
                            <label class="input-label pt-0 col-sm-3">Tipo Dependencia</label>
                            <div class="col-sm-9">
                                <select id="tipoDependenciaId" class="form-control form-control-sm" v-model="item.tipoDependenciaId" v-on:change="validItemForm('tipoDependenciaId')">
                                    <option v-for="itemT in tipoDependencias" v-bind:value="itemT.id" >{{itemT.nombre}}</option>
                                </select>
                            </div>
                        </div>                          
                        
                        <div class="form-group row pb-3">
                            <label class="input-label pt-0 col-sm-3">Persona Contacto</label>
                             <div class="col-sm-9">
                                <b-form-input id="personaDeContacto" class="form-control form-control-sm" v-model="item.personaDeContacto" v-on:keydown="validItemForm('personaDeContacto')" v-on:keyup="validItemForm('personaDeContacto')"></b-form-input>
                            </div>
                        </div> 

                        <div class="form-group row pb-3">
                            <label class="input-label pt-0 col-sm-3">Correo Contacto</label>
                             <div class="col-sm-9">
                                <b-form-input id="correoDeContacto" class="form-control form-control-sm" v-model="item.correoDeContacto" v-on:keydown="validItemForm('correoDeContacto')" v-on:keyup="validItemForm('correoDeContacto')"></b-form-input>
                            </div>
                        </div>

                        <div class="form-group row pb-3">
                            <div class="col-sm-3">
                                <label class="input-label pt-0">Token</label><br>
                                <button v-if="id != 0" class="btn btn-primary btn-sm" @click="generateToken">Generar Token</button>
                            </div>
                             <div class="col-sm-9">
                                <b-form-textarea id="token" class="form-control form-control-sm" v-model="token" readonly disabled="true" rows="8" style="height: 70px;"></b-form-textarea>
                            </div>
                        </div>

                        <div class="form-group row pb-3">
                            <label class="input-label pt-0 col-sm-3">Estatus</label>
                            <div class="col-sm-9">
                                <select id="estatusId" class="form-control form-control-sm" v-model="item.estatusId" v-on:change="validItemForm('estatusId')">
                                    <option v-for="itemT in estatus" v-bind:value="itemT.id" >{{itemT.text}}</option>
                                </select>
                            </div>
                        </div>
                    </div>
                </div>  
                <div class="row">
                    <div class="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6 offset-md-6 offset-lg-6 offset-xl-6">
                        <div class="form-group row pb-3 text-right"> 
                            <div class="col-12">
                                <router-link to="/catalogo/dependencias" class="text-white">
                                    <button class="btn btn-danger">
                                        <feather type="arrow-left" style="height: 18px; padding-top: 2px; color:white;"></feather>Regresar
                                    </button>
                                </router-link>
                                <!-- <Button text="Aceptar" btnClass="btn-dark" @click="save"/> -->
                                <button type="button" @click="save" class="ml-1 btn btn-dark"><feather type="save" style="height: 18px; padding-top: 2px;"></feather> Guardar</button>
                            </div>
                        </div>
                    </div>
                </div>              
            </form>
        </div>
    </div>
</template>
<style>
</style>
<script>
    import dependencyService        from "@/services/dependency.service";
    import municipioService         from "@/services/municipio.service";
    import estadoService            from "@/services/estado.service";
    import tipoDependencyService    from "@/services/tipoDependencia.service";
    import _                        from 'underscore';
    import Toast                    from '@/class/toast.class';
    import FormValidation           from '@/class/form-validation.class';
    import GlobalMessages           from '@/const/messages.const';
    import utilitiesService         from '@/services/utilities.service';
    import Button                   from '@/components/plantilla/Button';

    export default{
        name: 'form-roles',
        data(){
            return {
                title               : 'Nueva Dependencia',
                id                  : 0,
                item                : {},
                estatus             : [ {id: 1, text: 'ACTIVO'}, { id: 2, text: 'INACTIVO' } ],
                municipios          : [],
                estados             : [],
                tipoDependencias    : [],
                formValidation      : null,
                token               : ''
            }
        },
        methods:{           
            getById(){
                dependencyService.getById(this.id).then(res => {
                    res["mostrarEnReportex"]    = res["mostrarEnReportex"]  == 'S' ? true : false;
                    res["permiteRfcGenerico"]   = res["permiteRfcGenerico"] == '1' ? true : false;
                    this.token  = res.token;

                    delete res.token;

                    this.item   = res;

                    this.getMunicipalitiesForStateId(res.estadoId);
                }).catch(error => {
                    console.log(error)
                });
            },            
            getDataSelecteds(){    
                estadoService.get().then(res => {
                    this.estados = res
                }).catch(error => {
                    Toast.danger(error.message);
                });

                tipoDependencyService.get().then(res => {
                    this.tipoDependencias = res
                }).catch(error => {
                    Toast.danger(error.message);
                });
            },
            getMunicipalitiesForStateId(estadoId){
                municipioService.getByStateId(estadoId).then(res => {
                    this.municipios = res;
                }).catch(error => {
                    Toast.danger(error.message);
                });
            },
            validItemForm(id){
                this.formValidation.validByIdForm(id, this.item[id]);
                if(id == 'estadoId')
                    this.getMunicipalitiesForStateId(this.item[id]);
            },
            save(){
                if(!this.formValidation.isValid(this.item)){
                    Toast.danger(GlobalMessages.ERROR_FORM_INVALID);
                    return;
                }

                this.item["mostrarEnReportex"] = this.item["mostrarEnReportex"] ? 'S' :'N';

                dependencyService[this.id != 0 ? 'update' : 'create'](this.item).then(res => {
                    Toast.success(`Dependencia ${this.id != '' ? 'actualizada' : 'creada'} exitosamente`);
                    this.$router.push('/catalogo/dependencias');
                }).catch(err => {
                    Toast.danger(this.id != 0 ? GlobalMessages.ERROR_UPDATED_DATA : GlobalMessages.ERROR_CREATE_DATA);
                });       
            },
            generateToken(e){
                dependencyService.generateToken(this.id).then(res => {

                    this.token  = res.tokenDependency;
                }).catch(err => {

                });

                e.preventDefault();
            }
        },
        mounted(){
            let arrayControls = [
                { id: 'nombre',                 validators:{ required: true, maxLength: 200 }, default: '' },
                { id: 'nombreCorto',            validators:{ required: true, maxLength: 20 }, default: '' },
                { id: 'calle',                  validators:{ required: false, maxLength: 100 }, default: '' },
                { id: 'numeroInterior',         validators:{ required: false, maxLength: 45 }, default: '' },
                { id: 'numeroExterior',         validators:{ required: false, maxLength: 45 }, default: '' },
                { id: 'colonia',                validators:{ required: false, maxLength: 45 }, default: '' },
                { id: 'localidad',              validators:{ required: false, maxLength: 45 }, default: '' },
                { id: 'municipioId',            validators:{ required: true }, default: 0 },
                { id: 'estadoId',               validators:{ required: true }, default: 0 },
                { id: 'tipoDependenciaId',      validators:{ required: true }, default: 0 },
                { id: 'mostrarEnReportex',      validators:{ required: false, maxLength: 1 }, default: false },
                { id: 'personaDeContacto',      validators:{ required: false, maxLength: 45 }, default: '' },
                { id: 'telefonoDeContacto',     validators:{ required: false, maxLength: 45 }, default: '' },
                { id: 'correoDeContacto',       validators:{ required: false, maxLength: 100 }, default: '' },
                { id: 'sitioWeb',               validators:{ required: false, maxLength: 45 }, default: '' },
                { id: 'permiteRfcGenerico',     validators:{ required: false, maxLength: 1 }, default: true },
                { id: 'estatusId',              validators:{ required: true }, default: 1 },
            ];

            if(this.$route.params && this.$route.params.id){
                this.title  = 'Editar Dependencia';
                this.id     = this.$route.params.id;
                this.getById();
            }

            this.formValidation = new FormValidation(arrayControls);
            this.item           = this.formValidation.createStructure();
            this.getDataSelecteds();
        },
        components:{
            Button
        }
    }
</script>