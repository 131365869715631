<template>
  <div class="mobile-sidebar bg-white dark:bg-slate-800 shadow-base">
    <div class="logo-segment flex justify-between items-center px-4 py-6">
      <router-link  to="/home">
        <img src="logo-sivasa-large.png" style="height: 52px;"/>
      </router-link>
      <span class="cursor-pointer text-slate-900 dark:text-white text-2xl" @click="toggleMsidebar"><Icon icon="heroicons:x-mark"/></span>
    </div>
    <div class="sidebar-menu px-4 h-[calc(100%-100px)]" data-simplebar>
      <Navmenu :items="menuItems" />
    </div>
  </div>
</template>
<script setup>;
import { menuItems } from "../Header/Navtools/data";
import Navmenu from "./Navmenu";
import { Icon } from "@iconify/vue";
import { useThemeSettingsStore } from "@/plantilla/store/themeSettings";
const themeSettingsStore = useThemeSettingsStore();

const toggleMsidebar = () => {
  themeSettingsStore.toggleMsidebar()
};

</script>
<style lang="scss">
.mobile-sidebar {
  @apply fixed ltr:left-0 rtl:right-0 top-0   h-full   z-[9999]  w-[280px];
}
</style>
