<template>
    <div class="bg-white">        
        <div class="card-header pb-0">
            <div class="row">
                <div class="col-lg-4">
                    <h5>{{title}}</h5>
                </div>
            </div>
        </div>
        <div class="card-body">
            <form autocomplete="off" class="pace-y-4">
                <div class="row"> 
                   <div class="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
                        <div class="form-group row pb-3">
                            <label class="input-label pt-0 col-sm-3">Nombre</label>
                             <div class="col-sm-9">
                                <b-form-input id="nombre" class="form-control form-control-sm" v-model="item.nombre" v-on:keydown="validItemForm('nombre')" v-on:keyup="validItemForm('nombre')"></b-form-input>
                            </div>
                        </div>

                        <div class="form-group row pb-3">
                            <label class="input-label pt-0 col-sm-3">Estatus</label>
                             <div class="col-sm-9">
                                <select id="estatusId" class="form-control form-control-sm" v-model="item.estatusId" v-on:change="validItemForm('estatusId')">
                                    <option v-for="itemT in estatus" v-bind:value="itemT.id" >{{itemT.text}}</option>
                                </select>
                            </div>
                        </div>
                        
                    </div>
                    <div class="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
                        <div class="form-group row pb-3">
                            <label class="input-label pt-0 col-sm-3">Descripción</label>
                             <div class="col-sm-9">
                                <b-form-input id="descripcion" class="form-control form-control-sm" v-model="item.descripcion" v-on:keydown="validItemForm('descripcion')" v-on:keyup="validItemForm('descripcion')"></b-form-input>
                            </div>
                        </div>
                    </div>
                </div> 
                <hr>
                <div class="row">
                    <h5>Permisos</h5>
                    <br>
                    <div class="col" v-for="(menu, index) in listMenu">
                        <span v-if="menu.ruta == ''">
                            {{menu.nombre}}

                            <div class="row" style="margin-left: 10px;" v-for="(menuChildren, index2) in menu.childrens">
                                <span v-if="menuChildren.ruta == ''">
                                    {{menuChildren.nombre}}

                                    <div class="row" style="margin-left: 10px;" v-for="(menuThree, index3) in menuChildren.childrens">
                                        <b-form-checkbox v-model="menuThree.selected">{{menuThree.nombre}}</b-form-checkbox>
                                    </div>
                                </span>
                                <span v-if="menuChildren.ruta != ''">
                                    <b-form-checkbox v-model="menuChildren.selected">{{menuChildren.nombre}}</b-form-checkbox>
                                </span>
                            </div>
                        </span>
                        <span v-if="menu.ruta != ''">
                            <b-form-checkbox v-model="menu.selected">{{menu.nombre}}</b-form-checkbox>
                        </span>
                    </div>
                </div>
                <hr>
                <br>
                <div class="row">
                    <div class="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6 offset-md-6 offset-lg-6 offset-xl-6">
                        <div class="form-group row pb-3 text-right"> 
                            <div class="col-12">
                                <router-link to="/catalogo/roles" class="text-white">
                                    <button class="btn btn-danger">
                                        <feather type="arrow-left" style="height: 18px; padding-top: 2px; color:white;"></feather>Regresar
                                    </button>
                                </router-link>
                                <!-- <Button text="Aceptar" btnClass="btn-dark" @click="save"/> -->
                                <button type="button" @click="save" class="ml-1 btn btn-dark"><feather type="save" style="height: 18px; padding-top: 2px;"></feather> Guardar</button>
                            </div>
                        </div>
                    </div>
                </div>
            </form>
        </div>
    </div>
</template>
<style>
</style>
<script>
    import rolService               from "@/services/rol.service";
    import _                        from 'underscore';    
    import Toast                    from '@/class/toast.class';
    import FormValidation           from '@/class/form-validation.class';
    import GlobalMessages           from '@/const/messages.const';
    import OptionsMenu              from '@/services/optionsMenu.service';
    import Button                   from '@/components/plantilla/Button';

    export default{
        name: 'form-roles',
        data(){
            return {
                title               : 'Nuevo Rol',
                id                  : 0,
                item                : {},
                estatus             : [ {id: 1, text: 'ACTIVO'}, { id: 2, text: 'INACTIVO' } ],
                formValidation      : null,
                listMenu            : [],
                listMenuToSave      : []
            }
        },
        methods:{           
            getById(){
                rolService.getById(this.id).then(res => {
                    this.item = res;
                    setTimeout(() => {
                        this.setValueListMenu(this.listMenu);
                    }, 1000);
                }).catch(error => {
                    console.log(error)
                });
            },
            validItemForm(id){
                this.formValidation.validByIdForm(id, this.item[id]);
            },
            setValueListMenu(listMenu){
                listMenu.forEach(itemMenu => {
                    if(itemMenu.childrens && itemMenu.childrens.length > 0){
                        this.setValueListMenu(itemMenu.childrens)
                    }else{
                        itemMenu.selected = typeof(this.item.permissions.find(permissionId => permissionId == itemMenu.id)) != 'undefined'
                    }
                });
            },
            save(){

                if(!this.formValidation.isValid(this.item)){
                    Toast.danger(GlobalMessages.ERROR_FORM_INVALID);
                    return;
                }

                this.transformPermissionToSend()

                this.item.permissions = this.listMenuToSave;

                rolService[this.id != 0 ? 'update' : 'create'](this.item).then(res => {
                    Toast.success(`Rol ${this.id != '' ? 'actualizado' : 'creado'} exitosamente`);
                    this.$router.push('/catalogo/roles');
                }).catch(err => {
                    Toast.danger(this.id != 0 ? GlobalMessages.ERROR_UPDATED_DATA : GlobalMessages.ERROR_CREATE_DATA);
                });            
            },
            transformPermissionToSend(){
                this.listMenuToSave = [];

                this.fnRecursiveValueMenu(this.listMenu);
            },
            fnRecursiveValueMenu(listMenuFN){
                listMenuFN.forEach(itemMenu => {
                    if(itemMenu.childrens && itemMenu.childrens.length > 0){
                        let activeChildrens = itemMenu.childrens.filter(itemChildren => itemChildren.selected && itemChildren.selected == true);

                        if(activeChildrens.length > 0){
                            this.listMenuToSave = [...this.listMenuToSave, itemMenu.id, ...activeChildrens.map(actChildren => actChildren.id)];
                            this.fnRecursiveValueMenu(itemMenu.childrens);
                        }
                    }else if(itemMenu.selected && itemMenu.selected == true){
                        this.listMenuToSave.push(itemMenu.id)
                    }
                });

                let newMenuIDS = [];
                this.listMenuToSave = this.listMenuToSave.forEach(menuId => {

                    if(newMenuIDS.indexOf(menuId) == -1){
                        newMenuIDS.push(menuId)
                    }
                });

                this.listMenuToSave = newMenuIDS;
            }
        },
        mounted(){
            let arrayControls = [
                { id: 'nombre',                 validators:{ required: true, maxLength: 45 }, default: '' },
                { id: 'descripcion',            validators:{ required: false, maxLength: 300 }, default: '' },
                { id: 'estatusId',              validators:{ required: true }, default: 1 },
            ];

            if(this.$route.params && this.$route.params.id){
                this.title  = 'Editar Rol';
                this.id     = this.$route.params.id;
                this.getById();
            }

            this.formValidation = new FormValidation(arrayControls);
            this.item           = this.formValidation.createStructure();

            OptionsMenu.structure().then(response => {
                this.listMenu = response;
            }).catch(err => {

            });
        },
        components:{
            Button
        }
    }
</script>