<template>
    <div >
        <TransitionRoot :show="OpenClose" as="template">
            <Dialog as="div" @close="closeModal()" class="relative z-[999]">
                <TransitionChild
                    enter="duration-300 ease-out"
                    enter-from="opacity-0"
                    enter-to="opacity-100"
                    leave="duration-300 ease-in"
                    leave-from="opacity-100"
                    leave-to="opacity-0"
                >
                    <div class="fixed inset-0 bg-slate-900/50 backdrop-filter backdrop-blur-sm"/>
                </TransitionChild>

            <div class="fixed inset-0 overflow-y-auto">
                <div class="flex min-h-full justify-center text-center p-6 items-center">
                    <TransitionChild
                        as="template"
                        enter="duration-300  ease-out"
                        enter-from="opacity-0 scale-95"
                        enter-to="opacity-100 scale-100"
                        leave="duration-200 ease-in"
                        leave-from="opacity-100 scale-100"
                        leave-to="opacity-0 scale-95"
                        >
                        <DialogPanel class="w-full transform overflow-hidden rounded-md bg-white dark:bg-slate-800 text-left align-middle shadow-xl transition-all max-w-xl">
                            <div class="relative overflow-hidden py-4 px-5 text-white flex justify-between bg-slate-900 dark:bg-slate-800 dark:border-b dark:border-slate-700">
                                <h2 class="capitalize leading-6 tracking-wider font-medium text-base text-white">
                                    Detalle de Permiso Log
                                </h2>
                                <button @click="closeModal()" class="text-[22px]">
                                    <Icon icon="heroicons-outline:x" />
                                </button>
                            </div>
                            <div class="px-6 py-8">
                                <json-viewer :value="jsonData" :expand-depth=5 copyable sort></json-viewer>
                            </div>
                            <div class="px-4 py-3 flex justify-end space-x-3 border-t border-slate-100 dark:border-slate-700">
                                <Button text="Aceptar" btnClass="btn-dark" @click="closeModal()"/>
                            </div>
                        </DialogPanel>
                    </TransitionChild>
                </div>
            </div>
            </Dialog>
        </TransitionRoot>
    </div>
  </template>

<script>
    import { Icon }                 from '@iconify/vue';
    import JsonViewer               from 'vue-json-viewer'
    import Button                   from '@/components/plantilla/Button';
    import {
        TransitionRoot,
        TransitionChild,
        Dialog,
        DialogPanel,
        DialogTitle,
    } from '@headlessui/vue';

export default {
    name: 'ModalDetallePermisoLog',
    props: {
        visible : Boolean,
        jsonData: String,
    },
    data(){
        return{
            OpenClose: this.visible
        }
    },
    methods:{
            closeModal(isAccept = false){
                this.$emit('evt-close', isAccept)
            },
    },
    watch: { 
        visible: function(newVal, oldVal) {
            this.OpenClose = newVal;
        }
    },
    beforeDestroy(){
        this.OpenClose =false;
    },
    components:{
        Icon,
        Button,
        TransitionRoot,
        TransitionChild,
        Dialog,
        DialogPanel,
        DialogTitle,
        JsonViewer
    }

}
</script>