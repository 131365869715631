import { Buffer } from 'buffer';
import localStorageService  from "@/services/localStorage.service";

export default class Permission{
    key             = '';
    listPermission  = [];

    constructor(_key){
        this.key = _key;
    }

    getPermissions(){
        return Buffer.from(localStorageService.getItem('permissions'), 'base64').toString('ascii').split(',')
    }

    findByKey(key){
        this.listPermission = this.getPermissions();
        return this.listPermission.find(x => x == key);
    }

    existKey(){
        let find = this.findByKey(this.key);

        return find != null && find != '';
    }
}