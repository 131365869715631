<template>
    <div class="bg-white p-2">
        <div class="card-header pb-2">
            <div class="row">
                <div class="col-lg-4">
                    <h5>Usuarios</h5>
                </div>
                <div class="col-lg-8 text-right">         
                    <router-link to="usuarios/add" class="text-white">
                        <button class="btn btn-dark col-2"> Nuevo</button>
                    </router-link>
                </div>
            </div>
        </div>
        <div >
            <DataTable 
                :columnsKeys="columnsKeys" 
                :data="data" 
                :buttons="buttons" 
                :currentPage="currentPage" 
                :countData="countData" 
                v-on:change-page="changePage($event)" 
                v-on:click-button="clickButton($event)"                
                v-on:change-filters="changeFilters($event)"
                v-on:change-limit="changeLimit($event)"
            />
        </div>
        <TransitionRoot :show="showModal" as="template">
            <Dialog as="div" @close="showModal = false" class="relative z-[999]">
                <TransitionChild
                    enter="duration-300 ease-out"
                    enter-from="opacity-0"
                    enter-to="opacity-100"
                    leave="duration-300 ease-in"
                    leave-from="opacity-100"
                    leave-to="opacity-0"
                >
                    <div class="fixed inset-0 bg-slate-900/50 backdrop-filter backdrop-blur-sm"/>
                </TransitionChild>

            <div class="fixed inset-0 overflow-y-auto">
                <div class="flex min-h-full justify-center text-center p-6 items-center">
                    <TransitionChild
                        as="template"
                        enter="duration-300  ease-out"
                        enter-from="opacity-0 scale-95"
                        enter-to="opacity-100 scale-100"
                        leave="duration-200 ease-in"
                        leave-from="opacity-100 scale-100"
                        leave-to="opacity-0 scale-95"
                        >
                        <DialogPanel class="w-full transform overflow-hidden rounded-md bg-white dark:bg-slate-800 text-left align-middle shadow-xl transition-all max-w-xl">
                            <div class="relative overflow-hidden py-4 px-5 text-white flex justify-between bg-slate-900 dark:bg-slate-800 dark:border-b dark:border-slate-700">
                                <h2 class="capitalize leading-6 tracking-wider font-medium text-base text-white">
                                    Cambiar contraseña
                                </h2>
                                <button @click="hideModal" class="text-[22px]">
                                    <Icon icon="heroicons-outline:x" />
                                </button>
                            </div>
                            <div class="px-6 py-8">
                                <form ref="form">
                                    <div class="form-group row">
                                        <label class="input-label pt-0 col-sm-3">Contraseña</label>
                                        <div class="col-sm-9">
                                            <b-form-input id="contrasena" type="text" autocomplete="off" class="form-control form-control-sm password" v-model="itemModal.contrasena" v-on:keyup="validItemForm('contrasena');validConfirmedPassword();"></b-form-input>
                                        </div>
                                    </div>
                                    <br>
                                    <div class="form-group row">
                                        <label class="input-label pt-0 col-sm-3">Confirmar contraseña</label>
                                        <div class="col-sm-9">
                                            <b-form-input id="confirmarContrasena" type="txt" autocomplete="off" class="form-control form-control-sm password" v-model="itemModal.confirmarContrasena" v-on:keyup="validConfirmedPassword()"></b-form-input>
                                        </div>
                                    </div>
                                </form>
                            </div>
                            <div class="px-4 py-3 flex justify-end space-x-3 border-t border-slate-100 dark:border-slate-700">
                                <Button text="Cancelar" btnClass="btn-danger" @click="hideModal"/>
                                <Button text="Aceptar" btnClass="btn-dark" @click="clickGuardarNuevaContrasena()"/>
                            </div>
                        </DialogPanel>
                    </TransitionChild>
                </div>
            </div>
            </Dialog>
        </TransitionRoot>
    </div>

    <ModalMessage title="Usuario" message="¿Desea eliminar al usuario?" :visible="showModalMessage" v-on:evt-close="evtDeleteModal($event)"/>
</template>
<style>
    .password {
        text-security: disc;
        -webkit-text-security: disc;
        -moz-text-security: disc;
    }
</style>
<script>
    import DataTable                from '@/components/dataTable.vue';
    import userService              from '@/services/admUsuario.service';
    import FormValidation           from '@/class/form-validation.class';
    import GlobalMessages           from '@/const/messages.const';
    import Toast                    from '@/class/toast.class';
    import { Icon }                 from '@iconify/vue';
    import Button                   from '@/components/plantilla/Button';
    import ModalMessage             from '@/components/ModalMessage.vue';
    import {
        TransitionRoot,
        TransitionChild,
        Dialog,
        DialogPanel,
        DialogTitle,
    } from '@headlessui/vue';

    export default {
        name    : 'list-usuarios-page',
        data (){
            return{
                columnsKeys:[  
                    { text: 'Nombre Completo',      key: 'nombreCompleto',      isFilter: true },
                    { text: 'Usuario',              key: 'nombreUsuario',       isFilter: true },
                    { text: 'Correo Electrónico',   key: 'correoElectronico',   isFilter: true },
                    { text: 'Estatus',              key: 'estatusId',           isSelect: true, dataSelect:[ {id: 1, text: 'ACTIVO'}, { id: 2, text: 'INACTIVO' } ] },
                ],
                data    :[],
                buttons :[
                    { id: 1, typeIcon: 'heroicons-outline:pencil',  title: 'Editar',            class:'btn btn-sm btn-primary' },
                    { id: 2, typeIcon: 'heroicons-outline:key',     title: 'Cambiar contraseña',class:'btn btn-sm btn-warning' },
                    { id: 3, typeIcon: 'heroicons-outline:trash',   title: 'Eliminar',          class:'btn btn-sm btn-danger', 
                        evtShow: (index) => {
                            return this.data[index].estatusId == 1;
                        }
                    },
                ],
                countData   : 0,
                currentPage : 1,
                filters : { select: 'id, nombreCompleto, nombreUsuario, correoElectronico, estatusId', where:{} },
                idUsuarioChangePass: null,
                formValidation: new FormValidation([
                    { id: 'contrasena',             validators:{ required: true, minLength: 5 }, default: '' },
                    { id: 'confirmarContrasena',    validators:{ required: true, minLength: 5 }, default: '' },
                ]),
                itemModal       : {},
                showModal       : false,     
                showModalMessage: false,
                idSelected      : 0
            }
        },
        methods:{
            getData(){
                userService.paginated(this.filters).then(res => {
                    this.data       = res.rows;
                    this.countData  = res.total;
                }).catch(err => {

                });
            },
            changePage(event){
                this.filters.currentPage = event;
                this.currentPage         = event;
                this.getData();
            },
            clickButton(itemBtn){
                switch(itemBtn.button.id){
                    case 1://EDIT
                        this.$router.push({ path: `/configuracion/usuarios/${itemBtn.item.id}`});
                    break;
                    case 2://CHANGE PASSWORD
                        this.showModal              = true;
                        this.idUsuarioChangePass    = itemBtn.item.id;
                        this.itemModal              = this.formValidation.createStructure();
                    break;
                    case 3://DELETE
                        this.showModalMessage   = true;
                        this.idSelected         = itemBtn.item.id;
                    break;
                }
            },
            evtDeleteModal(response){
                this.showModalMessage = false;
                if(response){
                    userService.delete(this.idSelected).then(res => {
                        Toast.success('Usuario eliminado!');
                        this.getData();
                    }).catch(error => {
                        console.log(error);
                    });
                }
            },
            changeFilters(event){
                this.filters.where          = event;
                this.filters.currentPage    = 1;
                this.getData();
            },
            changeLimit(event){
                this.filters.limit = event;
                this.getData();
            },
            validItemForm(id){
                this.formValidation.validByIdForm(id, this.itemModal[id]);
            },
            validConfirmedPassword(){ 
                return this.formValidation.validPassword(this.itemModal, 'contrasena', 'confirmarContrasena');  
            },
            clickGuardarNuevaContrasena() {

                if(!this.formValidation.isValid(this.itemModal) || !this.validConfirmedPassword()){
                    !this.formValidation.isValid(this.itemModal) ? Toast.danger(GlobalMessages.ERROR_FORM_INVALID): ''
                    !this.validConfirmedPassword() ? Toast.danger('Favor de validar la confirmación de la contraseña.'): ''
                    return;
                }

                let params = {
                    userId      : this.idUsuarioChangePass,
                    password    : this.itemModal.contrasena
                };

                userService.changePassword(params).then(res => {
                    Toast.success('Se actualizo correctamente la contraseña del usuario');
                    
                    this.showModal = false;
                }).catch(err => {
                    Toast.danger(err.message || 'Problemas para actualizar la contraseña');
                });
            },
            hideModal(e) {
                this.showModal = false;
            }     
        },
        mounted(){
            this.getData();
        },
        components:{
            DataTable,
            Icon,
            Button,
            TransitionRoot,
            TransitionChild,
            Dialog,
            DialogPanel,
            DialogTitle,
            ModalMessage
        }
    }
</script>
