<template>
    <div
      class="min-h-screen flex flex-col justify-center items-center text-center py-20"
    >
      <img src="/images/all-img/404.svg" alt="" />
      <div class="max-w-[546px] mx-auto w-full mt-12">
        <h4 class="text-slate-900 mb-4">El usuario no cuenta con los permisos requeridos</h4>
      </div>
      <div class="max-w-[300px] mx-auto w-full">
        <router-link
          to="/home"
          class="btn btn-dark dark:bg-slate-800 block text-center"
        >
          Ir al Inicio
        </router-link>
      </div>
    </div>
  </template>
  <script>
  export default {};
  </script>
  <style lang=""></style>
  