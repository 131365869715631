'use strict'

import env from '@/env/env.json';
import envQA from '@/env/env.qa.json';
import envProd from '@/env/env.prod.json';

let environment = process.env.NODE_ENV;

export default {
    API_REST    : environment == 'production' ? envProd.API_REST     : environment == 'qa' ? envQA.API_REST     : env.API_REST,
    API_SOCKET  : environment == 'production' ? envProd.API_SOCKET   : environment == 'qa' ? envQA.API_SOCKET   : env.API_SOCKET,
    CLIENT_ID   : environment == 'production' ? envProd.CLIENT_ID    : environment == 'qa' ? envQA.CLIENT_ID    : env.CLIENT_ID
}