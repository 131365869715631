export default class FormValidation{
    _form = [];
    _value;

    constructor(form){
        this._form = form;
    }

    createStructure(){
        let obj = {};
        this._form.forEach(x => { obj[x.id] = 'default' in x ? x.default : '' });

        return obj;
    }

    isValid(modelItem){
        let valid = true;

        this._form.forEach(x => {
            this.value = modelItem[x.id];

            if(!this.validByItem(x)){
                valid = false;
            }
        });

        return valid;
    }

    validPassword(model, id1, id2){
        if(model[id1] != model[id2]){
            this.addClassInvalid(id2);
            return false;
        }else{
            this.validByIdForm(id2, model[id2]);
            return true;
        }
    }

    addClassInvalid(id){
        $(`#${id}`).removeClass('is-valid');
        $(`#${id}`).addClass('is-invalid');
    }

    addClassValid(id){
        $(`#${id}`).removeClass('is-invalid');
        $(`#${id}`).addClass('is-valid');
    }

    validAutocomplete(id, value){
        let stringClass  = '';
        let find        = this._form.find(x =>  x.id === id);

        if(find){
           this.value = value;
           stringClass = this.validByItem(find) ? 'is-valid' : 'is-invalid';
        }

        return stringClass;
    }

    validByIdForm(id, value){
        let find = this._form.find(x =>  x.id === id);

        if(find){
           this.value = value;
           return this.validByItem(find);
        }
    }

    validByItem(x){
        if((this.isRequired(x) && this.isEmpty()) || this.isValidMaxLength(x) || this.isValidEmail(x) || this.isValidMinLength(x)){
            this.addClassInvalid(x.id);
            return false;
        }else{
            this.addClassValid(x.id);
            return true;
        }
    }

    isValidEmail(x){
        //let regexEmail = /^\w+([ \ .-]?\w+)*@\w+([ \ .-]?\w+)*(\.\w{2,3})+$/; se borraron dos barras invertidas
        let regexEmail = /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/;

        return x.validators && x.validators.email && !regexEmail.test(this.value);
    }

    isEmpty(){
        return this.value === '' || this.value === 0;
    }

    isRequired(x){
        return x.validators && x.validators.required;
    }

    isValidMaxLength(x){
        return x.validators && x.validators.maxLength && this.value && this.value.length > x.validators.maxLength;
    }

    isValidMinLength(x){
        return x.validators && x.validators.minLength && this.value && this.value.length < x.validators.minLength;
    }

    static numberWithCommas(x) {
        return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    }
}