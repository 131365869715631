import home from '../pages/home/home';
import listCultivoClasificacion from '@/pages/catalogos/cultivoClasificacion/listCultivoClasificacion';
import formCultivoClasificacion from '@/pages/catalogos/cultivoClasificacion/formCultivoClasificacion';
import permissionKeys    from '@/const/permission.const';
import Permission        from "@/class/permission.class";

function ValidatePermission(to, from, next){
    let permission = new Permission(permissionKeys.PERM_CLASIFICACION_CULTIVOS);

    permission.existKey() ? next() : next({ name: 'no-autorizado' });
}

const routesCultivoClasificacion = [{
    path        : '/catalogo',
    component   : home,
    children    :[
        { path: 'cultivoClasificacion',     component: listCultivoClasificacion, beforeEnter: [ValidatePermission] },
        { path: 'cultivoClasificacion/add', component: formCultivoClasificacion, beforeEnter: [ValidatePermission] },
        { path: 'cultivoClasificacion/:id', component: formCultivoClasificacion, beforeEnter: [ValidatePermission] }
    ]
}]

export default{
    getRoutes(){
        return routesCultivoClasificacion;
    }
}