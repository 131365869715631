import home                     from '../pages/home/home';
import avancesEmisionPermisos   from '@/pages/reportes/avancesEmisionPermisos';
import avancesPermisosSiembra   from '@/pages/reportes/avancesPermisosSiembra';
import emisionDePermisos        from '@/pages/reportes/emisionDePermisos';
import estractoPorCultivo       from '@/pages/reportes/estractoPorCultivo';
import permisoValidado          from '@/pages/reportes/permisoValidado';
import listPermisosLogs         from '@/pages/permisos/listPermisosLogs';
import permissionKeys           from '@/const/permission.const';
import Permission               from "@/class/permission.class";

function ValidatePermissionSiembra(to, from, next){
    let permission = new Permission(permissionKeys.PERM_REPORTES_AVANCE_SIEMBRA);

    permission.existKey() ? next() : next({ name: 'no-autorizado' });
}
function ValidatePermission(to, from, next){
    let permission = new Permission(permissionKeys.PERM_REPORTES_AVANCE_PERMISOS);

    permission.existKey() ? next() : next({ name: 'no-autorizado' });
}

function ValidatePermissionValidacion(to, from, next){
    let permission = new Permission(permissionKeys.PERM_REPORTES);

    permission.existKey() ? next() : next({ name: 'no-autorizado' });
}

function ValidatePermissionLogs(to, from, next){
    let permission = new Permission(permissionKeys.PERM_REPORTES_PERMISO_LOGS);

    permission.existKey() ? next() : next({ name: 'no-autorizado' });
}

function ValidateEmisionPermission(to, from, next){
    let permission = new Permission(permissionKeys.PERM_REPORTES_EMISION_PERMISOS);

    permission.existKey() ? next() : next({ name: 'no-autorizado' });
}

function ValidateEstractoPorCultivo(to, from, next){
    let permission = new Permission(permissionKeys.PERM_REPORTES_ESTRACTO_POR_CULTIVO);

    permission.existKey() ? next() : next({ name: 'no-autorizado' });
}

const routes = [{
    path        : '/reportes',
    component   : home,
    children    :[
        { path: '',                             component: avancesEmisionPermisos, beforeEnter: [ValidatePermissionSiembra] },
        { path: 'avance-siembra-por-permisos',  component: avancesPermisosSiembra, beforeEnter: [ValidatePermission] },
        { path: 'permiso-validado',             component: permisoValidado,        beforeEnter: [ValidatePermissionValidacion] },
        { path: 'permiso-logs',                 component: listPermisosLogs,       beforeEnter: [ValidatePermissionLogs] },
        { path: 'emision-permiso-siembra',      component: emisionDePermisos,      beforeEnter: [ValidateEmisionPermission] },
        { path: 'estracto-por-cultivo',         component: estractoPorCultivo,     beforeEnter: [ValidateEstractoPorCultivo] }
    ]
}]

export default{
    getRoutes(){
        return routes;
    }
}