import home                 from '../pages/home/home';
import listUsuarios         from '@/pages/catalogos/configuracion/usuarios/listUsuarios';
import formUsuarios         from '@/pages/catalogos/configuracion/usuarios/formUsuarios';
import permissionKeys       from '@/const/permission.const';
import Permission           from "@/class/permission.class";

function ValidatePermissionUser(to, from, next){
    let permission = new Permission(permissionKeys.PERM_USUARIOS);

    permission.existKey() ? next() : next({ name: 'no-autorizado' });
}

const routesUsuario = [{
    path        : '/configuracion',
    component   : home,
    children    :[ 
        { path: 'usuarios',     component: listUsuarios, beforeEnter: [ValidatePermissionUser]},
        { path: 'usuarios/add', component: formUsuarios, beforeEnter: [ValidatePermissionUser]},
        { path: 'usuarios/:id', component: formUsuarios, beforeEnter: [ValidatePermissionUser]}
    ]
}]

export default{
    getRoutes(){
        return routesUsuario;
    }
}