<template>
  <div class="cursor-pointer text-slate-901 dark:text-white text-2xl" @click="toggleMsidebar">
    <Icon icon="heroicons-outline:menu-alt-3" />
  </div>
</template>
<script setup>

import Icon from "@/components/plantilla/Icon";
import { useThemeSettingsStore } from "@/plantilla/store/themeSettings";

const themeSettingsStore = useThemeSettingsStore()

const toggleMsidebar = () => {console.log('antees de abrir el menu movil')
  console.log(themeSettingsStore)
  themeSettingsStore.toggleMsidebar()
  console.log('despues de abrir el menu movil')
};
</script>
<style lang="scss"></style>
