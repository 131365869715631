<template>
   <main class="app-wrapper">
        <Header :class="window.width > 1280 ? switchHeaderClass() : ''" />
        <!-- end header -->
        <Sidebar v-if="this.$store.themeSettingsStore.menuLayout === 'vertical' && this.$store.themeSettingsStore.sidebarHidden === false && window.width > 1280" />  
        <Transition name="mobilemenu">
          <mobile-sidebar v-if="window.width < 1280 && this.$store.themeSettingsStore.mobielSidebar"/>
        </Transition>
        <div class="content-wrapper transition-all duration-150" :class="window.width > 1280 ? switchHeaderClass() : ''">
          <div class="page-content" :class="this.$route.meta.appheight ? 'h-full' : 'page-min-height'">
            <div :class="`transition-all duration-150 ${this.$store.themeSettingsStore.cWidth === 'boxed' ? 'container mx-auto': ''}`">
              <transition name="router-animation" mode="out-in" appear>
                <router-view style="padding: 10px;"></router-view>
                </transition>
              <!-- <router-view v-slot="{ Component }">
                <transition name="router-animation" mode="out-in" appear>
                  <component :is="Component"></component>
                </transition>
              </router-view> -->
            </div>
          </div>
        </div>
        <!-- main sidebar end -->
   </main>
</template>
<script>
    import window         from "@/plantilla/mixins/window";    
    import Header         from "@/components/plantilla/Header";
    import Sidebar        from "@/components/plantilla/Sidebar/";
    import FooterMenu     from "@/components/plantilla/Footer/FooterMenu.vue";
    import MobileSidebar  from "@/components/plantilla/Sidebar/MobileSidebar.vue";
    // import env            from '@/config/env';
    // import io             from "socket.io-client";

    // const socket = io(env.API_SOCKET);

    export default {
        mixins: [window],
        name        : 'home-page',
        components  : {
            Header,
            Sidebar,
            FooterMenu,
            MobileSidebar
            //Header
        },
        methods: {
            switchHeaderClass() {
                if (
                    this.$store.themeSettingsStore.menuLayout === "horizontal" ||
                    this.$store.themeSettingsStore.sidebarHidden
                ) {
                    return "ltr:ml-0 rtl:mr-0";
                } else if (this.$store.themeSettingsStore.sidebarCollasp) {
                    return "ltr:ml-[72px] rtl:mr-[72px]";
                } else {
                    return "ltr:ml-[248px] rtl:mr-[248px]";
                }
            },
        },
        mounted(){
          // setTimeout(() => {
          //   socket.emit('event', {message: 'sssss'})
          //   console.log('emit')
          // }, 2000);

          // socket.on('new_permission', (response) => {
          //   console.log('response de socket => ', response);
          // });
        }
    }
</script>
<style lang="scss">
.router-animation-enter-active {
  animation: coming 0.2s;
  animation-delay: 0.1s;
  opacity: 0;
}
.router-animation-leave-active {
  animation: going 0.2s;
}

@keyframes going {
  from {
    transform: translate3d(0, 0, 0) scale(1);
  }
  to {
    transform: translate3d(0, 4%, 0) scale(0.93);
    opacity: 0;
  }
}
@keyframes coming {
  from {
    transform: translate3d(0, 4%, 0) scale(0.93);
    opacity: 0;
  }
  to {
    transform: translate3d(0, 0, 0) scale(1);
    opacity: 1;
  }
}
@keyframes slideLeftTransition {
  0% {
    opacity: 0;
    transform: translateX(-20px);
  }
  100% {
    opacity: 1;
    transform: translateX(0px);
  }
}
.mobilemenu-enter-active {
  animation: slideLeftTransition 0.24s;
}

.mobilemenu-leave-active {
  animation: slideLeftTransition 0.24s reverse;
}

.page-content {
  @apply md:pt-6 md:pb-[37px] md:px-6 pt-[15px] px-[15px] pb-24;
}
.page-min-height {
  min-height: calc(var(--vh, 1vh) * 100 - 132px);
}
</style>