<template>
    <div class="bg-white p-2">
        <div class="card-header pb-2">
            <div class="row">
                <div class="col-lg-4">
                    <h5>Programa Agrícola</h5>
                </div>
                <div v-if="isRolAdmin" class="col-lg-8 text-right">         
                    <router-link to="programa-agricola/add" class="text-white">
                        <button class="btn btn-dark col-2"> Nuevo</button>
                    </router-link>
                </div>
            </div>
        </div>
        <div >
            <DataTable 
                :columnsKeys="columnsKeys" 
                :data="data" 
                :buttons="buttons" 
                :currentPage="currentPage" 
                :countData="countData" 
                v-on:change-page="changePage($event)" 
                v-on:click-button="clickButton($event)"                
                v-on:change-filters="changeFilters($event)"
                v-on:change-limit="changeLimit($event)"
            />
        </div>
    </div>

    <ModalMessage title="Programa Agrícola" message="¿Desea eliminar el programa agrícola?" :visible="showModalMessage" v-on:evt-close="evtDeleteModal($event)"/>
</template>
<style>
</style>
<script>
    import DataTable                from '@/components/dataTable.vue';
    import programaAgricolaService  from '@/services/programaAgricola.service';
    import utilitiesService         from '@/services/utilities.service';
    import FormValidation           from '@/class/form-validation.class';
    import Toast                    from '@/class/toast.class';
    import { Icon }                 from '@iconify/vue';
    import Button                   from '@/components/plantilla/Button';
    import ModalMessage             from '@/components/ModalMessage.vue';

    export default {
        name    : 'list-programa-agricola-page',
        data (){
            return{
                columnsKeys:[
                    { text: 'Nombre',                       key: 'nombre',                      isFilter: true },
                    { text: 'Ciclo Agrícola',               key: 'cicloAgricola',               isFilter: true },
                    { text: 'Programado',                   key: 'programado',                  isFilter: true }
                ],
                data    :[],
                buttons :[
                    { id: 1, typeIcon: 'heroicons-outline:pencil',  title: 'Editar',            class:'btn btn-sm btn-primary' },
                    { id: 2, typeIcon: 'heroicons-outline:trash',   title: 'Eliminar',          class:'btn btn-sm btn-danger'  },
                ],
                countData   : 0,
                currentPage : 1,
                filters : { select: '', where:{} },    
                showModalMessage: false,
                idSelected      : 0,
                isRolAdmin      : utilitiesService.isRolAdmin()
            }
        },
        methods:{
            getData(){
                programaAgricolaService.paginated(this.filters).then(res => {
                    this.countData  = res.total;
                    this.data       = res.rows.map(x => {
                        x.programado = FormValidation.numberWithCommas(x.programado);
                        return x;
                    });
                }).catch(err => {

                });
            },
            changePage(event){
                this.filters.currentPage = event;
                this.currentPage         = event;
                this.getData();
            },
            clickButton(itemBtn){
                switch(itemBtn.button.id){
                    case 1://EDIT
                        this.$router.push({ path: `/catalogo/programa-agricola/${itemBtn.item.id}`});
                    break;
                    case 2://DELETE
                        this.showModalMessage   = true;
                        this.idSelected         = itemBtn.item.id;
                    break;
                }
            },
            evtDeleteModal(response){
                this.showModalMessage = false;
                if(response){
                    programaAgricolaService.delete(this.idSelected).then(res => {
                        Toast.success('Programa agrícola eliminado!');
                        this.getData();
                    }).catch(error => {
                        console.log(error);
                    });
                }
            },
            changeFilters(event){
                this.filters.where          = event;
                this.filters.currentPage    = 1;
                this.getData();
            },
            changeLimit(event){
                this.filters.limit = event;
                this.getData();
            } 
        },
        mounted(){
            this.getData();
        },
        components:{
            DataTable,
            Icon,
            Button,
            ModalMessage
        }
    }
</script>
