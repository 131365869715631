import home             from '../pages/home/home';
import listCultivos     from '@/pages/catalogos/cultivos/listCultivos';
import formCultivos     from '@/pages/catalogos/cultivos/formCultivos';
import permissionKeys    from '@/const/permission.const';
import Permission        from "@/class/permission.class";

function ValidatePermission(to, from, next){
    let permission = new Permission(permissionKeys.PERM_CULTIVOS);

    permission.existKey() ? next() : next({ name: 'no-autorizado' });
}

const routesCultivos = [{
    path        : '/catalogo',
    component   : home,
    children    :[
        { path: 'cultivos',     component: listCultivos, beforeEnter: [ValidatePermission]},
        { path: 'cultivos/add', component: formCultivos, beforeEnter: [ValidatePermission]},
        { path: 'cultivos/:id', component: formCultivos, beforeEnter: [ValidatePermission] }
    ]
}]

export default{
    getRoutes(){
        return routesCultivos;
    }
}