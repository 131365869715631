import home from '../pages/home/home';
import listRoles from '@/pages/catalogos/roles/listRoles';
import formRoles from '@/pages/catalogos/roles/formRoles';
import permissionKeys    from '@/const/permission.const';
import Permission        from "@/class/permission.class";

function ValidatePermission(to, from, next){
    let permission = new Permission(permissionKeys.PERM_ROLES);

    permission.existKey() ? next() : next({ name: 'no-autorizado' });
}

const routesRoles = [{
    path        : '/catalogo',
    component   : home,
    children    :[
        { path: 'roles',     component: listRoles, beforeEnter: [ValidatePermission] },
        { path: 'roles/add', component: formRoles, beforeEnter: [ValidatePermission] },
        { path: 'roles/:id', component: formRoles, beforeEnter: [ValidatePermission] }
    ]
}]

export default{
    getRoutes(){
        return routesRoles;
    }
}