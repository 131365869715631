import home from '../pages/home/home';
import listTipoDependencia from '@/pages/catalogos/tipoDependencia/listTipoDependencia';
import formTipoDependencia from '@/pages/catalogos/tipoDependencia/formTipoDependencia';
import permissionKeys    from '@/const/permission.const';
import Permission        from "@/class/permission.class";

function ValidatePermission(to, from, next){
    let permission = new Permission(permissionKeys.PERM_TIPO_DEPENDENCIA);

    permission.existKey() ? next() : next({ name: 'no-autorizado' });
}

const routesTipoDependencia = [{
    path        : '/catalogo',
    component   : home,
    children    :[
        { path: 'tipoDependencia',     component: listTipoDependencia, beforeEnter: [ValidatePermission] },
        { path: 'tipoDependencia/add', component: formTipoDependencia, beforeEnter: [ValidatePermission] },
        { path: 'tipoDependencia/:id', component: formTipoDependencia, beforeEnter: [ValidatePermission] }
    ]
}]

export default{
    getRoutes(){
        return routesTipoDependencia;
    }
}