<template>
    <div class="bg-white">
        <div class="card-header pb-2">
            <div class="row">
                <div class="col-lg-4">
                    <h5>Permisos Logs</h5>
                </div>
            </div>
        </div>
        <div >
            <DataTable 
                :columnsKeys="columnsKeys" 
                :data="data" 
                :buttons="buttons" 
                :currentPage="currentPage" 
                :countData="countData" 
                v-on:change-page="changePage($event)" 
                v-on:click-button="clickButton($event)"
                v-on:change-filters="changeFilters($event)"
                v-on:change-limit="changeLimit($event)"
            />
        </div>
        <ModalDetallePermisoLog :jsonData="jsonData" :visible="showModalMessage" v-on:evt-close="evtDeleteModal($event)"/>
    </div>
</template>
<style>
    .font-size-column{
        font-size: small;
    }
    .text-center{
        align-items: center !important;
    }
</style>
<script>
    import DataTable                from '@/components/dataTable.vue';
    import permisosService          from '@/services/permisos.service';
    import { Icon }                 from '@iconify/vue';
    import Button                   from '@/components/plantilla/Button';
    import Toast                    from '@/class/toast.class';
    import ModalDetallePermisoLog   from './modalPermisoLog.vue';

    export default {
        name    : 'list-usuarios-page',
        data (){
            return{
                columnsKeys:[  
                    { text: 'Dependencia',              key: 'dependencia', isFilter: true, class: 'font-size-column', classBody: 'font-size-column' },
                    { text: 'Respuesta Error',          key: 'response',    isFilter: true, class: 'font-size-column', classBody: 'font-size-column'},
                    { text: 'Respuesta Detalle',        key: 'errorDetail', isFilter: true, class: 'font-size-column', classBody: 'font-size-column'},
                    { text: 'Fecha Creación',           key: 'createdAt',   isDate: true, class: 'font-size-column', classBody: 'font-size-column'},
                ],
                data    :[],
                buttons :[
                    { id: 1, typeIcon: 'heroicons-outline:document-text',  title: 'Detalle', class:'btn btn-sm btn-primary' },
                ],
                countData   : 0,
                currentPage : 1,
                filters     : { select: 'permLog.id, dep.nombre AS dependencia, permLog.response, permLog.errorDetail, permLog.createdAt', where:{}, order: 'DESC' },
                idSelected  : 0,
                showModalMessage: false,
                jsonData    : {}
            }
        },
        methods:{
            getData(){
                if(this.filters.where && this.filters.where.like){
                    let selects = this.filters.select.split(',');

                    this.filters.where.like.forEach(itemLike => {
                        itemLike.key = selects.find(x => x.includes(itemLike.key))
                    });
                }

                permisosService.paginatedLogs(this.filters).then(res => {
                    this.data       = res.rows;
                    this.countData  = res.total;
                }).catch(err => {
                    Toast.danger(err.message);
                });
            },
            changePage(event){
                this.filters.currentPage = event;
                this.currentPage         = event;
                this.getData();
            },
            clickButton(itemBtn){
                let id = itemBtn.item.id;
                switch(itemBtn.button.id){
                    case 1://Permiso Log
                        permisosService.getPermissionLogById(id).then(res => {
                            this.showModalMessage   = true;
                            this.jsonData           = res.request;
                        }).catch(err => {

                        })
                    break;
                }
            },
            changeFilters(event){
                this.filters.where          = event;
                this.filters.currentPage    = 1;
                this.getData();
            },
            changeLimit(event){
                this.filters.limit = event;
                this.getData();
            },
            evtDeleteModal(response){
                this.showModalMessage = false;
            },
        },
        mounted(){
            this.getData();
        },
        components:{
            DataTable,
            Icon,
            Button,
            ModalDetallePermisoLog
        }
    }
</script>
