<template>
    <div class="loginwrapper">
    <div class="lg-inner-column">
      <div class="left-column relative z-[1]">
        <div
          class="absolute left-0 h-full w-full"
        >
          <img
            :src="sideImg"
            alt=""
            class="w-full object-contain img-fluid"
          />
        </div>
      </div>
      <div class="right-column relative">
        <div
          class="inner-content h-full flex flex-col bg-white dark:bg-slate-800"
        >
          <div class="auth-box h-full flex flex-col justify-center">
            <router-link to="/">
                <img
                  :src="logoPrincipal"
                  alt=""
                  class="mx-auto w-100"
                />
              </router-link>
            <div class="text-center 2xl:mb-10 mb-4">
              <h4 class="font-medium">Iniciar Sesión</h4>
              <div class="text-slate-500 text-base">
                Inicie sesión en su cuenta para acceder a la información
              </div>
            </div>
            <form class="space-y-4" autocomplete="off">
                <Textinput
                    autofocus
                    id="user"
                    label="Usuario"
                    type="text"
                    name="user"
                    placeholder="Ingrese usuario"
                    v-model="loginP.user"
                    classInput="h-[48px]"
                    v-on:keyup="validItemForm('user')"
                    v-on:keyup.enter="login"
                ></Textinput>
                <Textinput
                    id="password"
                    label="Contraseña"
                    type="password"
                    name="password"
                    placeholder="Ingrese contraseña"
                    v-model="loginP.password"
                    classInput="h-[48px]"
                    hasicon
                    v-on:keyup="validItemForm('password')" v-on:keyup.enter="login"
                ></Textinput>
                <div id="example3"></div>
                <button type="button" class="btn btn-dark block w-full text-center" @click="login()">Iniciar Sesión</button>
            </form>
            <!-- <div class="relative border-b-[#9AA2AF] border-opacity-[16%] border-b pt-6"></div> -->
            <!-- <div class="md:max-w-[345px] mx-auto font-normal text-slate-500 dark:text-slate-400 mt-12 uppercase text-sm">
              OLVIDO SU CONTRASEÑA? 
              <router-link to="/register" class="text-slate-900 dark:text-white font-medium hover:underline"> RECUPERARLA</router-link>
            </div> -->
          </div>
          <div class="auth-footer text-center">
            Versión: {{APPLICATION_VERSION}}<br>
            Copyright 2023,CESAVESIN.
          </div>
        </div>
      </div>
    </div>
  </div>
  <component is="script" src="js/recaptcha.js"></component>
  <component is="script" src="https://www.google.com/recaptcha/api.js?onload=onloadCallback&render=explicit" async></component>
  <component is="script" src="https://www.googletagmanager.com/gtag/js?id=G-M82SZTKT8G"></component>
  <component is="script" src="js/google-analitics.js"></component>
</template>
<script>
    import authenticationServices   from "@/services/authentication.services";
    import localStorageService      from "@/services/localStorage.service";
    import Toast                    from '@/class/toast.class';
    import FormValidation           from '@/class/form-validation.class';
    import GlobalMessages           from '@/const/messages.const';
    import Textinput                from "@/components/plantilla/Textinput";
    import { version } from "../../package.json";

    export default {
        name    : 'login-page',
        data(){
            return{
                init            : true,
                isInit          : false,
                isSignIn        : false,
                loginP          : {},
                formValidation  : new FormValidation([
                    { id: 'user',    validators:{ required: true }},
                    { id: 'password',validators:{ required: true }},
                ]), 
                logo          : "images/auth/imgLogin.jpg", //"images/auth/ils1.svg" imgLogin.jpg
                logoWhite     : "logo-sivasa.png",  //"images/logo/logo-white.svg"
                logoPrincipal : "logo-sivasa-large.png",  //"images/logo/logo-white.svg"
                sideImg       : "images/auth/imgLogin.jpg", //"images/auth/ils1.svg" imgLogin.jpg
                APPLICATION_VERSION : '0.0.0'
            }
        },
        methods:{
            validItemForm(id){
                this.formValidation.validByIdForm(id, this.loginP[id]);
            },
            login(){
                if(!this.formValidation.isValid(this.loginP)){
                    Toast.danger(GlobalMessages.ERROR_FORM_INVALID);
                    return;
                }

                let tokenRecaptcha = localStorage.getItem('recaptcha');

                if(!tokenRecaptcha){
                  Toast.danger('Favor de capturar recaptcha, si no aparece recargar página');
                  return;
                }

                authenticationServices.login(this.loginP).then(res => {
                  localStorageService.setItem('token',   res.token)
                  localStorageService.setItem('userName',res.userName);
                  localStorageService.setItem('tokenDependency',res.tokenDependency);
                  localStorageService.setItem('permissions',res.permissions);
                  localStorageService.setItem('menuOptions', JSON.stringify(res.menuOptions || []));
                  localStorageService.setItem('roleName',res.roleName);
                  localStorage.removeItem('recaptcha')
                  Toast.success('Bienvenido!');

                  this.$router.push('/home');
                }).catch(error => {
                  Toast.danger(typeof(error) == 'undefined' ? 'Servicio no disponible' : error.message);
                });
            }
        },
        components: {
            Textinput
        },
        mounted(){
            this.loginP = this.formValidation.createStructure();
            this.APPLICATION_VERSION = version || '1.0.0';
        }
    }
</script>