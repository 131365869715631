import home from '../pages/home/home';
import listModuloDeRiego from '@/pages/catalogos/moduloDeRiego/listModuloDeRiego';
import formModuloDeRiego from '@/pages/catalogos/moduloDeRiego/formModuloDeRiego';
import permissionKeys    from '@/const/permission.const';
import Permission        from "@/class/permission.class";

function ValidatePermission(to, from, next){
    let permission = new Permission(permissionKeys.PERM_MODULO_RIEGO);

    permission.existKey() ? next() : next({ name: 'no-autorizado' });
}

const routesModuloDeRiego = [{
    path        : '/catalogo',
    component   : home,
    children    :[
        { path: 'moduloDeRiego',     component: listModuloDeRiego, beforeEnter: [ValidatePermission] },
        { path: 'moduloDeRiego/add', component: formModuloDeRiego, beforeEnter: [ValidatePermission] },
        { path: 'moduloDeRiego/:id', component: formModuloDeRiego, beforeEnter: [ValidatePermission] }
    ]
}]

export default{
    getRoutes(){
        return routesModuloDeRiego;
    }
}