import home from '../pages/home/home';
import listDistritoDeDesarrolloRural from '@/pages/catalogos/distritoDeDesarrolloRural/listDistritoDeDesarrolloRural';
import formDistritoDeDesarrolloRural from '@/pages/catalogos/distritoDeDesarrolloRural/formDistritoDeDesarrolloRural';
import permissionKeys    from '@/const/permission.const';
import Permission        from "@/class/permission.class";

function ValidatePermission(to, from, next){
    let permission = new Permission(permissionKeys.PERM_DISTRITO_DESARROLLO_RURAL);

    permission.existKey() ? next() : next({ name: 'no-autorizado' });
}

const routesDistritoDeDesarrolloRural = [{
    path        : '/catalogo',
    component   : home,
    children    :[
        { path: 'distritoDeDesarrolloRural',     component: listDistritoDeDesarrolloRural, beforeEnter: [ValidatePermission] },
        { path: 'distritoDeDesarrolloRural/add', component: formDistritoDeDesarrolloRural, beforeEnter: [ValidatePermission] },
        { path: 'distritoDeDesarrolloRural/:id', component: formDistritoDeDesarrolloRural, beforeEnter: [ValidatePermission] }
    ]
}]

export default{
    getRoutes(){
        return routesDistritoDeDesarrolloRural;
    }
}