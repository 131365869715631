import home                 from '../pages/home/home';
import listProgramaAgricola from '@/pages/catalogos/programaAgricola/listProgramaAgricola';
import formProgramaAgricola from '@/pages/catalogos/programaAgricola/formProgramaAgricola';
import permissionKeys       from '@/const/permission.const';
import Permission           from "@/class/permission.class";

function ValidatePermission(to, from, next){
    let permission = new Permission(permissionKeys.PERM_PROGRAMA_AGRICOLA);

    permission.existKey() ? next() : next({ name: 'no-autorizado' });
}

const routes = [{
    path        : '/catalogo',
    component   : home,
    children    :[
        { path: 'programa-agricola',     component: listProgramaAgricola,   beforeEnter: [ValidatePermission] },
        { path: 'programa-agricola/add', component: formProgramaAgricola,   beforeEnter: [ValidatePermission] },
        { path: 'programa-agricola/:id', component: formProgramaAgricola,   beforeEnter: [ValidatePermission] }
    ]
}]

export default{
    getRoutes(){
        return routes;
    }
}