import home from '../pages/home/home';
import listTipoSiembra from '@/pages/catalogos/tipoSiembra/listTipoSiembra';
import formTipoSiembra from '@/pages/catalogos/tipoSiembra/formTipoSiembra';
import permissionKeys    from '@/const/permission.const';
import Permission        from "@/class/permission.class";

function ValidatePermission(to, from, next){
    let permission = new Permission(permissionKeys.PERM_TIPO_SIEMBRA);

    permission.existKey() ? next() : next({ name: 'no-autorizado' });
}

const routesTipoSiembra = [{
    path        : '/catalogo',
    component   : home,
    children    :[
        { path: 'tipoSiembra',     component: listTipoSiembra, beforeEnter: [ValidatePermission] },
        { path: 'tipoSiembra/add', component: formTipoSiembra, beforeEnter: [ValidatePermission] },
        { path: 'tipoSiembra/:id', component: formTipoSiembra, beforeEnter: [ValidatePermission] }
    ]
}]

export default{
    getRoutes(){
        return routesTipoSiembra;
    }
}