import axios from "axios";

const API =  'permission';

export default{
    exportAvanceEmision(params){
        return axios.post(`${API}/excel`,  params);
    },
    exportAvancePermisoSiembra(params){
        return axios.post(`${API}/reporte-avance-permiso-siembra`,  params);
    },
    exportPermisoValidados(params){
        return axios.post(`${API}/reporte-permiso-validado`,  params);
    },
    exportEmisionPermisosDeSiembra(params){
        return axios.post(`${API}/reporte-emision-permisos-siembra`,  params);
    },
    exportEstractoPorCultivo(params){
        return axios.post(`${API}/reporte-estracto-por-cultivo`,  params);
    }
}